<ng-template #loadNextBtn let-entity>
    <p-button *ngIf="!store.loading"
              [label]="('common.loadNext' | translate) + '...'"
              [link]="true" (onClick)="loadNext(entity)"
              [disabled]="loadingNext" [loading]="loadingNext">
    </p-button>
</ng-template>

<p-dialog
    [(visible)]="visible"
    [style]="{ width: '50vw', 'height': '80vh' }"
    [breakpoints]="{ '992px': '75vw', '576px': '90vw' }"
    [closeOnEscape]="true"
    [closable]="true"
    [dismissableMask]="true"
    [draggable]="false"
    [modal]="true"
    (onShow)="focusOnInput()"
>
    <ng-template pTemplate="header">
        <div class="flex flex-column w-full">
            <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true" (onChange)="lazyLoad()">
                <p-tabPanel [header]="'common.all' | translate">
                </p-tabPanel>
                <p-tabPanel [header]="'donor.labelMulti' | translate">
                </p-tabPanel>
                <p-tabPanel [header]="'donation.labelMulti' | translate">
                </p-tabPanel>
                <p-tabPanel [header]="'collection.labelMulti' | translate">
                </p-tabPanel>
                <p-tabPanel [header]="'history.labelMulti' | translate">
                </p-tabPanel>
            </p-tabView>
            <app-input-search #search [form]="form"></app-input-search>
        </div>
    </ng-template>

    <div class="flex gap-1 flex-column">
        <!-- All tab -->
        <ng-container *ngIf="activeIndex === 0">
            <ng-container *ngFor="let item of store.data.results$ | async">
                <app-search-result [item]="item" (clickChange)="hide()"
                                   [withTag]="true"></app-search-result>
            </ng-container>
            <ng-container *ngIf="!(store.data.results$ | async)?.length && !store.loading">
                {{ 'common.noResults' | translate }}
            </ng-container>
            <ng-container *ngIf="(store.data.results$ | async).length && (!store.isDonorLast || !store.isDonationLast || !store.isCollectionLast)"
                          [ngTemplateOutlet]="loadNextBtn"
                          [ngTemplateOutletContext]="{ $implicit: 'all' }"></ng-container>
        </ng-container>

        <!-- Donor tab -->
        <ng-container *ngIf="activeIndex === 1">
            <ng-container *ngFor="let item of store.onlyDonors$ | async">
                <app-search-result [item]="item" (clickChange)="hide()"></app-search-result>
            </ng-container>
            <ng-container *ngIf="!(store.onlyDonors$ | async)?.length && !store.loading">
                {{ 'common.noResults' | translate }}
            </ng-container>
            <ng-container *ngIf="(store.data.results$ | async).length && !store.isDonorLast"
                          [ngTemplateOutlet]="loadNextBtn"
                          [ngTemplateOutletContext]="{ $implicit: 'donor' }"></ng-container>
        </ng-container>

        <!-- Donation tab -->
        <ng-container *ngIf="activeIndex === 2">
            <ng-container *ngFor="let item of store.onlyDonations$ | async">
                <app-search-result [item]="item" (clickChange)="hide()"></app-search-result>
            </ng-container>
            <ng-container *ngIf="!(store.onlyDonations$ | async)?.length && !store.loading">
                {{ 'common.noResults' | translate }}
            </ng-container>
            <ng-container *ngIf="(store.data.results$ | async).length && !store.isDonationLast"
                          [ngTemplateOutlet]="loadNextBtn"
                          [ngTemplateOutletContext]="{ $implicit: 'donation' }"></ng-container>
        </ng-container>

        <!-- Collection tab -->
        <ng-container *ngIf="activeIndex == 3">
            <ng-container *ngFor="let item of store.onlyCollections$ | async">
                <app-search-result [item]="item" (clickChange)="hide()"></app-search-result>
            </ng-container>
            <ng-container *ngIf="!(store.onlyCollections$ | async)?.length && !store.loading">
                {{ 'common.noResults' | translate }}
            </ng-container>
            <ng-container *ngIf="(store.data.results$ | async).length && !store.isCollectionLast"
                          [ngTemplateOutlet]="loadNextBtn"
                          [ngTemplateOutletContext]="{ $implicit: 'collection' }"></ng-container>
        </ng-container>

        <!-- History tab -->
        <ng-container *ngIf="activeIndex == 4">
            <ng-container *ngFor="let item of store.onlyHistory$ | async">
                <app-search-result [item]="item" (clickChange)="hide()" [withTag]="true"></app-search-result>
            </ng-container>
            <ng-container *ngIf="!(store.onlyHistory$ | async)?.length && !store.loading">
                {{ 'common.noResults' | translate }}
            </ng-container>
            <ng-container *ngIf="(store.data.historyResults$ | async).length && !store.isHistoryLast"
                          [ngTemplateOutlet]="loadNextBtn"
                          [ngTemplateOutletContext]="{ $implicit: 'history' }"></ng-container>
        </ng-container>
    </div>
    <div *ngIf="store.loading" class="w-full h-full flex justify-content-center align-items-center">
        <p-progressSpinner></p-progressSpinner>
    </div>

</p-dialog>
