import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateUserSettingsInput, UserSettingsObject } from '@it2go/types';

export const changeGroupId = createAction(
  '[LayoutApp] Change Group',
  props<{ groupId: string }>(),
);

export const changeUserSettings = createAction(
  '[LayoutChangeUserSettings] Change User Settings',
  props<{ input: UpdateUserSettingsInput }>(),
);

export const changeUserSettingsFailure = createAction(
  '[layoutChangeUserSettingsFailure] Change User Settings Failure',
  props<{ error: HttpErrorResponse }>(),
);

export const changeUserSettingsSuccess = createAction(
  '[layoutChangeUserSettingsSuccess] Change User Settings Success',
);

export const getUserSettings = createAction(
  '[layoutGetUserSettings] Get User Settings',
);

export const getUserSettingsSuccess = createAction(
  '[layoutGetUserSettingsSuccess] Get User Settings Success',
  props<{ userSettings: UserSettingsObject }>(),
);

export const getUserSettingsFailure = createAction(
  '[layoutGetUserSettingsFailure] Get User Settings Failure',
  props<{ error: HttpErrorResponse }>(),
);

export const changeLanguage = createAction(
  '[LayoutApp] Change Language',
  props<{ language: string }>(),
);

export const setBackendUrl = createAction(
  '[SetBackendUrl] Set Backend Url',
  props<{ backendUrl: string }>(),
);
