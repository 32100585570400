<ng-template #copyIdBtn>
    <i
        [ngClass]="{ pi: true, 'pi-copy': !copied, 'pi-spinner': copied, 'pi-spin': copied}"
        [pTooltip]="'common.copyId' | translate"
        (click)="copyId($event)"
    ></i>
</ng-template>

<div [ngClass]="{ 'cursor-pointer': !!this.link }" (click)="goto()">
    <p-card>
        <div class="infoBlock">
            <div class="flex flex-column singleCell gap-1" style="z-index: 1;">

                <!-- Donor -->
                <ng-container *ngIf="item.entity === 'donor'">
                    <div class="mb-1 flex gap-2 align-items-center copyBtn">
                        <b>{{ item!.data.name }}</b>
                        <span class="text-500">{{ entityId }}</span>
                        <ng-container [ngTemplateOutlet]="copyIdBtn"></ng-container>
                    </div>
                    <div *ngFor="let it of item.data.emails || []; let first = first">
                        {{ first ? '' : ', ' }}{{ it.email }}
                    </div>
                    <div>{{ 'donor.fields.comment' | translate }}: <span
                    >{{ item!.data.comment }}</span>
                    </div>
                </ng-container>
                <!-- /Donor -->

                <!-- Donation -->
                <ng-container *ngIf="item.entity === 'donation'">
                    <div class="flex gap-2 align-items-center copyBtn">
                        <b>{{ 'donation.fields.donorName' | translate }}: {{ item!.data.donorName }}</b>
                        <span class="text-500">{{ entityId }}</span>
                        <ng-container [ngTemplateOutlet]="copyIdBtn"></ng-container>
                    </div>
                    <div>
                        {{ 'donation.fields.amount' | translate }}: {{ item!.data.amount }}
                    </div>
                    <div>
                        {{ 'donation.fields.collectionName' | translate }}: {{ item!.data.collectionName }}
                    </div>
                    <div>
                        {{ 'donation.donationForm.label' | translate }}: <span
                        *ngIf="item!.data.donationForm">{{ item!.data.donationForm.name }}</span>
                    </div>
                </ng-container>
                <!-- /Donation -->

                <!-- Collection -->
                <ng-container *ngIf="item.entity === 'collection'">
                    <div class="flex gap-2 align-items-center copyBtn">
                        <b class="mr-2">{{ 'collection.fields.name' | translate }}: {{ item!.data.name }}</b>
                        <span class="text-500">{{ entityId }}</span>
                        <ng-container [ngTemplateOutlet]="copyIdBtn"></ng-container>
                    </div>
                    <div>
                        {{ 'collection.fields.comment' | translate }}: {{ item!.data.comment }}
                    </div>
                    <div>
                        {{ 'collection.fields.active' | translate }}:
                        <app-check-or-cross [value]="item!.data.active"></app-check-or-cross>
                    </div>
                    <div>
                        {{ 'collection.fields.longTerm' | translate }}:
                        <app-check-or-cross [value]="item!.data.longTerm"></app-check-or-cross>
                    </div>
                </ng-container>
                <!-- /Collection -->

                <!-- History -->
                <ng-container *ngIf="item.entity === 'history'">
                    <div class="flex gap-2 align-items-center copyBtn">
                        <b *ngIf="item.data['entityName'] === 'donor'">{{ item!.tree.data.details | formatPerson }}</b>
                        <b *ngIf="item.data['entityName'] === 'donation'">{{ item!.tree.data.donorName }}</b>
                        <b *ngIf="item.data['entityName'] === 'collection'">{{ item!.tree.data.name }}</b>
                        <span class="ml-2 text-500">{{ entityId }}</span>
                        <ng-container [ngTemplateOutlet]="copyIdBtn"></ng-container>
                    </div>
                    <div>
                        <span>{{ 'history.changeFields' | translate }}: </span>
                        <span *ngFor="let leaf of item!.tree.alteredLeaves; let index = index"><span
                            *ngIf="index>0">, </span>
                            <span>{{ item.data['entityName'] + '.fields.' + leaf | translate }}</span>
                        </span>
                    </div>
                    <p-inplace #historyTree (onActivate)="stopPropagation($event)"
                               (onDeactivate)="stopPropagation($event)">
                        <ng-template pTemplate="display">
                            <p-button [label]="'history.showChanges' | translate" [link]="true"></p-button>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <app-audit-log-tree (click)="stopPropagation($event)" [tree]="item.tree.tree"
                                                [entityName]="item.data.entityName"></app-audit-log-tree>
                            <div class="flex justify-content-end">
                                <p-button [label]="'history.hideChanges' | translate" [link]="true"
                                          (onClick)="hideHistoryTree($event)"></p-button>
                            </div>
                        </ng-template>
                    </p-inplace>
                    <div>
                        {{ 'history.fields.comment' | translate }}: {{ item!.data.comment }}
                    </div>
                    <div>
                        {{ 'history.fields.authorName' | translate }}: {{ item!.data.authorName }}
                    </div>
                </ng-container>
                <!-- /History -->

            </div>
            <div *ngIf="withTag" class="flex justify-content-end align-items-start relative singleCell"
                 style="top: -1.5rem;">
                <p-tag [severity]="severity" [value]="tagLabel + '.label' | translate"></p-tag>
            </div>
            <div class="flex justify-content-end align-items-end relative singleCell" style="bottom: -1.5rem;">
                <span *ngIf="dateLabel.length">{{ dateLabel | translate }}: </span>{{ item.date | localDate }}
            </div>
        </div>
    </p-card>
</div>
