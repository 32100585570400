import { personFrag } from './person.types';

export const folderStatusHistoryFrag = `
        id
        status
        dateFrom
        dateTo
`;

export const folderMedicationFrag = `
    id
    active
    customMedication
    customMedicationName
    dosing {
        afternoon
        evening
        forenoon
        hours
        morning
        night
        noon
    }
    dosingNote
    dosingPeriod {
        custom
        days
        hours
    }
    from
    internalNote
    medicationCode
    packageTo
    prescribedBy
    to
`;

export const serviceRecordFrag = `
    id
    createdAt
    createdByUser
    data
`;

export const epicrisisFrag = `
    id
    data
    createdAt
`;

export const folderPersonFrag = `
    id
    status
    person {
        ${personFrag}
    }
    workplaces {
        id
        name
        workplaceId
        dateFrom
        dateTo
    }
`;

export const folderFrag = `
    id
    status
    epicrises {
        ${epicrisisFrag}
    }
    folderMedications {
        ${folderMedicationFrag}
    }
    serviceRecords {
        ${serviceRecordFrag}
    }
    person {
        ${personFrag}
    }
`;

export const folderGridItemFrag = `
    id
    personId
    serviceId
    status
    person {
        ${personFrag}
    }
`;
