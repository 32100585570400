export const donorActivityFrag = `
    id
    userId
    userName
    type
    note
    date
    filePath
`;

export const donorActivityGridItemFrag = `
    id
    userId
    userName
    type
    note
    date
    filePath
`;
