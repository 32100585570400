import { addressFrag } from './common.types';
import { userGridItemFrag } from './user.types';

export const personAddressFrag = `
    id
    type
    validFrom
    validTo
    serviceAddress
    address {
        ${addressFrag}
    }
`;

export const personFolderFrag = `
    id
    serviceId
`;

export const personGridItemFrag = `
    id
    name
    surname
    titleBeforeName
    titleAfterName
    birthdate
    addresses {
        ${personAddressFrag}
    }
    insuranceAbbr
    insuranceNumber
    folders {
        ${personFolderFrag}
    }
`;

export const personFrag = `
    id
    name
    birthdate
    addresses {
        ${personAddressFrag}
    }
    insuranceAbbr
    insuranceType
    insuranceNumber
    created
    updated
    surname
    birthSurname
    alias
    foreigner
    sex
    email
    phone
    image
    insuredFrom
    state
    communikePassword
`;

export const epicrisisGridItemFrag = `
    id
    data
    createdAt
`;

export const serviceRecordGridItemFrag = `
    id
    data
    createdAt
    createdByUser
    user {
        ${userGridItemFrag}
    }
`;

export const personContactFrag = `
    circumstance
    created
    id
    info
    note
    position
    primary
    relation
    type
    userId
    active
    purposeOfAuthorization
    scopeOfAuthorization
    dateOfAuthorization
    authorizationFrom
    authorizationTo
`;

export const personContactGridItemFrag = `
    id
    note
    type
    position
    relation
    circumstance
    created
    primary
    active
    info
    purposeOfAuthorization
    scopeOfAuthorization
    dateOfAuthorization
    authorizationFrom
    authorizationTo
    user {
        ${userGridItemFrag}
    }
`;
