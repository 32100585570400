export const menuThemes = {
    light: {
        primaryLightColorPercentage: 40,
        blue: {
            primaryColor: '#2196f3',
            primaryTextColor: '#ffffff',
            highlightBg: '#e3f2fd',
            highlightTextColor: '#495057',
        },
        cyan: {
            primaryColor: '#00bcd4',
            primaryTextColor: '#ffffff',
            highlightBg: '#e0f7fa',
            highlightTextColor: '#495057',
        },
        deeppurple: {
            primaryColor: '#673ab7',
            primaryTextColor: '#ffffff',
            highlightBg: '#ede7f6',
            highlightTextColor: '#495057',
        },
        green: {
            primaryColor: '#4caf50',
            primaryTextColor: '#ffffff',
            highlightBg: '#e8f5e9',
            highlightTextColor: '#495057',
        },
        indigo: {
            primaryColor: '#3f51b5',
            primaryTextColor: '#ffffff',
            highlightBg: '#e8eaf6',
            highlightTextColor: '#495057',
        },
        lightgreen: {
            primaryColor: '#8bc34a',
            primaryTextColor: '#ffffff',
            highlightBg: '#f1f8e9',
            highlightTextColor: '#495057',
        },
        orange: {
            primaryColor: '#ff9800',
            primaryTextColor: '#121212',
            highlightBg: '#fff3e0',
            highlightTextColor: '#495057',
        },
        pink: {
            primaryColor: '#e91e63',
            primaryTextColor: '#ffffff',
            highlightBg: '#fce4ec',
            highlightTextColor: '#495057',
        },
        purple: {
            primaryColor: '#9c27b0',
            primaryTextColor: '#ffffff',
            highlightBg: '#f3e5f5',
            highlightTextColor: '#495057',
        },
        teal: {
            primaryColor: '#009688',
            primaryTextColor: '#ffffff',
            highlightBg: '#e0f2f1',
            highlightTextColor: '#495057',
        },
    },
    dim: {
        primaryLightColorPercentage: 70,
        blue: {
            primaryColor: '#90caf9',
            primaryTextColor: '#212529',
            highlightBg: '#90caf928',
            highlightTextColor: '#ffffffdd',
        },
        cyan: {
            primaryColor: '#80deea',
            primaryTextColor: '#212529',
            highlightBg: '#80deea28',
            highlightTextColor: '#ffffffdd',
        },
        deeppurple: {
            primaryColor: '#9575cd',
            primaryTextColor: '#ffffff',
            highlightBg: '#9575cd28',
            highlightTextColor: '#ffffffdd',
        },
        green: {
            primaryColor: '#a5d6a7',
            primaryTextColor: '#212529',
            highlightBg: '#a5d6a728',
            highlightTextColor: '#ffffffdd',
        },
        indigo: {
            primaryColor: '#7986cb',
            primaryTextColor: '#ffffff',
            highlightBg: '#7986cb28',
            highlightTextColor: '#ffffffdd',
        },
        lightgreen: {
            primaryColor: '#c5e1a5',
            primaryTextColor: '#212529',
            highlightBg: '#c5e1a528',
            highlightTextColor: '#ffffffdd',
        },
        orange: {
            primaryColor: '#ffcc80',
            primaryTextColor: '#212529',
            highlightBg: '#ffcc8028',
            highlightTextColor: '#ffffffdd',
        },
        pink: {
            primaryColor: '#f48fb1',
            primaryTextColor: '#212529',
            highlightBg: '#f48fb128',
            highlightTextColor: '#ffffffdd',
        },
        purple: {
            primaryColor: '#ba68c8',
            primaryTextColor: '#ffffff',
            highlightBg: '#ba68c828',
            highlightTextColor: '#ffffffdd',
        },
        teal: {
            primaryColor: '#80cbc4',
            primaryTextColor: '#212529',
            highlightBg: '#80cbc428',
            highlightTextColor: '#ffffffdd',
        },
    },
    dark: {
        primaryLightColorPercentage: 70,
        blue: {
            primaryColor: '#90caf9',
            primaryTextColor: '#212529',
            highlightBg: '#90caf928',
            highlightTextColor: '#ffffffdd',
        },
        cyan: {
            primaryColor: '#80deea',
            primaryTextColor: '#212529',
            highlightBg: '#80deea28',
            highlightTextColor: '#ffffffdd',
        },
        deeppurple: {
            primaryColor: '#9575cd',
            primaryTextColor: '#ffffff',
            highlightBg: '#9575cd28',
            highlightTextColor: '#ffffffdd',
        },
        green: {
            primaryColor: '#a5d6a7',
            primaryTextColor: '#212529',
            highlightBg: '#a5d6a728',
            highlightTextColor: '#ffffffdd',
        },
        indigo: {
            primaryColor: '#7986cb',
            primaryTextColor: '#ffffff',
            highlightBg: '#7986cb28',
            highlightTextColor: '#ffffffdd',
        },
        lightgreen: {
            primaryColor: '#c5e1a5',
            primaryTextColor: '#212529',
            highlightBg: '#c5e1a528',
            highlightTextColor: '#ffffffdd',
        },
        orange: {
            primaryColor: '#ffcc80',
            primaryTextColor: '#212529',
            highlightBg: '#ffcc8028',
            highlightTextColor: '#ffffffdd',
        },
        pink: {
            primaryColor: '#f48fb1',
            primaryTextColor: '#212529',
            highlightBg: '#f48fb128',
            highlightTextColor: '#ffffffdd',
        },
        purple: {
            primaryColor: '#ba68c8',
            primaryTextColor: '#ffffff',
            highlightBg: '#ba68c828',
            highlightTextColor: '#ffffffdd',
        },
        teal: {
            primaryColor: '#80cbc4',
            primaryTextColor: '#212529',
            highlightBg: '#80cbc428',
            highlightTextColor: '#ffffffdd',
        },
    },
};
