const pages = [
    '/login',
    '/logon',
    '/forgotten-password',
    '/reset-password',
    '/embed',
    '/share-file',
];

export function pageWithoutLayout(url: string): boolean {
    return !!pages.find((it) => url.includes(it));
}
