import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { OkObject, PersonGridObject } from '@it2go/types';
import { CompareDonorInput } from '../../../../types/src/chcrm/input-type/compare-donor.input';
import { CompareDonorFieldObject } from '../../../../types/src/chcrm/object-type/donor/compare-donor.object';
import { MergeDonorInput } from '../../../../types/src/chcrm/input-type/merge-donor.input';
import {
    DonorActivityGridObject,
    DonorActivityObject,
} from '../../../../types/src/chcrm/object-type/donor/donor-activity.object';
import { donorActivityFrag, donorActivityGridItemFrag } from './types/donor-activity.types';
import { DonorActivityCreateInput } from '../../../../types/src/chcrm/input-type/donor/donor-activity-create.input';
import { DonorActivityDeleteInput } from '../../../../types/src/chcrm/input-type/donor/donor-activity-delete.input';
import { DonorDeleteInput } from '../../../../types/src/chcrm/input-type/donor/donor-delete.input';
import { Observable } from 'rxjs';
import {
    DonorCategorySyncInput,
} from '../../../../types/src/chcrm/input-type/donor-category/donor-category-sync.input';
import { DonorGetInput } from '../../../../types/src/chcrm/input-type/donor/donor-get.input';
import { DonorStatisticsObject } from '../../../../types/src/chcrm/object-type/donor/donor-statistics.object';
import {
    CollectionCategoryObject,
} from '../../../../types/src/chcrm/object-type/collection/collection-category.object';
import { DonorCreateInput } from '@libs/types/src/chcrm/input-type/donor/donor-create.input';
import { DonorUpdateInput } from '@libs/types/src/chcrm/input-type/donor/donor-update.input';
import {
    donorGridItemDetailFrag,
    donorGridItemFrag,
    donorGridItemSearchFrag,
    donorStatisticsFrag,
} from './types/donor.types';
import { GqlService } from '../modules/content/service/gql.service';
import { filterFrag } from './types/filter.types';

@Injectable()
export class DonorService extends GqlService {

    getDonors(input: GridInput | null) {
        return this.query<PersonGridObject>(
            `
                query($input: GridInput) {
                    donor {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${donorGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createDonor(input: DonorCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DonorCreateInput!) {
                    donor {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateDonor(input: DonorUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DonorUpdateInput!) {
                    donor {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getDetailDonors(input: GridInput | null) {
        return this.query<PersonGridObject>(
            `
                query($input: GridInput) {
                    donor {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${donorGridItemDetailFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getSearchDonors(input: GridInput | null) {
        return this.query<PersonGridObject>(
            `
                query($input: GridInput) {
                    donor {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${donorGridItemSearchFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    compare(input: CompareDonorInput) {
        return this.query<CompareDonorFieldObject[]>(
            `
                query($input: CompareDonorInput!) {
                    donor {
                        compare(input: $input) {
                            key
                            left
                            right
                        }
                    }
                }
            `,
            { input },
        );
    }

    merge(input: MergeDonorInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: MergeDonorInput!) {
                    donor {
                        merge(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getDonorActivities(input: GridInput | null) {
        return this.query<DonorActivityGridObject>(
            `
                query ($input: GridInput) {
                    donorActivity {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${donorActivityGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createDonorActivity(input: DonorActivityCreateInput) {
        return this.mutate<DonorActivityObject>(
            `
                mutation ($input: DonorActivityCreateInput!) {
                    donorActivity {
                        create(input: $input) {
                            ${donorActivityFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteDonorActivity(input: DonorActivityDeleteInput) {
        return this.mutate(
            `
                mutation($input: DonorActivityDeleteInput!) {
                    donorActivity {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteDonor(input: DonorDeleteInput): Observable<OkObject> {
        return this.mutate<OkObject>(
            `
                mutation($input: DonorDeleteInput!) {
                    donor {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateCategories(input: DonorCategorySyncInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DonorCategorySyncInput!) {
                    donorCategory {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getDonorCategories() {
        return this.query<CollectionCategoryObject[]>(
            `
                query {
                    donorCategory {
                        list {
                            id
                            category
                        }
                    }
                }
            `,
        );
    }

    getStatistics(input: DonorGetInput) {
        return this.query<DonorStatisticsObject>(
            `
                query($input: DonorGetInput!) {
                    donor {
                        statistics(input: $input) {
                            ${donorStatisticsFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
