import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    AddressObject,
    BasicInfoObject,
    CenterObject,
    CreateAddressInput,
    CreateBasicInfoInput,
    CreateOrganizationInput,
    CreateOrganizationPersonInput,
    GetOrganizationInput,
    LinkOrganizationPersonInput,
    OkObject,
    OrganizationObject,
    OrganizationPersonGridObject,
    OrganizationPersonObject,
    OrganizationsGridObject,
    UnlinkOrganizationPersonInput,
    UpdateAddressInput,
    UpdateBasicInfoInput,
    UpdateCenterInput,
    UpdateOrganizationPersonInput,
} from '@it2go/types';
import { Observable } from 'rxjs';
import { addressFrag } from './types/common.types';
import { filterFrag } from './types/filter.types';
import {
    basicInfoFrag,
    centerFrag,
    organizationFrag,
    organizationGridItemFrag,
    personFrag,
    personGridItemFrag,
} from './types/organization.types';
import {
    OrganizationSettingsGridObject,
    OrganizationSettingsObject,
} from '@libs/types/src/organization/object-type/organization/organization-settings.object';
import { organizationSettingsFrag } from './types/organization-settings.types';
import {
    OrganizationSettingsGetInput,
} from '@libs/types/src/organization/input-type/organization/organization-settings-get.input';
import {
    OrganizationSettingsUpdateInput,
} from '@libs/types/src/organization/input-type/organization/organization-settings-update.input';
import { GqlService } from '../modules/content/service/gql.service';
import {
    UserOrganizationAccessInput,
} from '@libs/types/src/organization/input-type/user/user-organization-access.input';

@Injectable()
export class OrganizationService extends GqlService {
    getOrganizations(input: GridInput | null = null) {
        return this.query<OrganizationsGridObject>(
            `
                query($input: GridInput) {
                    organization {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${organizationGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getOrganization(input: GetOrganizationInput): Observable<OrganizationObject> {
        return this.query<OrganizationObject>(`
                query($input: GetOrganizationInput!) {
                    organization {
                        get(input: $input) {
                            ${organizationFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getOrCreateOrganizationSetting(input: OrganizationSettingsGetInput): Observable<OrganizationSettingsObject> {
        return this.query<OrganizationSettingsObject>(`
                query($input: OrganizationSettingsGetInput!) {
                    organizationSettings {
                        getOrCreate(input: $input) {
                            ${organizationSettingsFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateOrganizationSetting(input: OrganizationSettingsUpdateInput): Observable<OrganizationSettingsObject> {
        return this.mutate<OrganizationSettingsObject>(`
                mutation($input: OrganizationSettingsUpdateInput!) {
                    organizationSettings {
                        update(input: $input) {
                            ${organizationSettingsFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    removeOrganizationSetting(input: OrganizationSettingsGetInput): Observable<OkObject> {
        return this.mutate<OkObject>(`
                mutation($input: OrganizationSettingsGetInput!) {
                    organizationSettings {
                        remove(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getOrganizationSettings(input: GridInput | null): Observable<OrganizationSettingsGridObject> {
        return this.query<OrganizationObject>(`
                query($input: GridInput) {
                    organizationSettings {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${organizationSettingsFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createOrganization(input: CreateOrganizationInput) {
        return this.mutate<OrganizationObject>(
            `
                mutation ($input: CreateOrganizationInput!) {
                    organization {
                        create(input: $input) {
                            ${organizationFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getOrganizationPersons(input: GridInput | null = null) {
        return this.query<OrganizationPersonGridObject>(
            `
                query($input: GridInput) {
                    organizationPerson {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${personGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    linkPerson(input: LinkOrganizationPersonInput) {
        return this.mutate<OkObject>(
            `
                mutation ($input: LinkOrganizationPersonInput!) {
                    organizationPerson {
                        link (input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    unlinkPerson(input: UnlinkOrganizationPersonInput) {
        return this.mutate<OkObject>(
            `
                mutation ($input: UnlinkOrganizationPersonInput!) {
                    organizationPerson {
                        unlink (input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    createOrganizationPerson(input: CreateOrganizationPersonInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: CreateOrganizationPersonInput!) {
                    organizationPerson {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateOrganizationPerson(input: UpdateOrganizationPersonInput): Observable<OrganizationPersonObject> {
        return this.mutate<OrganizationPersonObject>(
            `
                mutation ($input: UpdateOrganizationPersonInput!) {
                    organizationPerson {
                        update(input: $input) {
                           ${personFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    createCenter(organizationId: number): Observable<CenterObject> {
        return this.mutate<CenterObject>(`
                mutation {
                    center {
                        create(input: {
                            organizationId: ${organizationId},
                            addressIds: []
                            basicInfoIds: []
                            contactPersonIds: []
                        }) {
                            ${centerFrag}
                        }
                    }
                }
            `);
    }

    updateCenter(input: UpdateCenterInput): Observable<OkObject> {
        return this.mutate<OkObject>(`
                mutation($input: UpdateCenterInput!) {
                    center {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteCenter(id: number) {
        return this.mutate<OkObject>(`
                mutation {
                    center {
                        delete(input: {
                            id: ${id}
                        }) {
                            ok
                        }
                    }
                }
            `);
    }

    createAddress(input: CreateAddressInput): Observable<AddressObject> {
        return this.mutate<AddressObject>(`
                mutation ($input: CreateAddressInput!) {
                    address {
                        create(input: $input) {
                           note
                           validFrom
                           address {
                            ${addressFrag}
                           }
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateAddress(input: UpdateAddressInput): Observable<AddressObject> {
        return this.mutate<AddressObject>(`
                mutation ($input: UpdateAddressInput!) {
                    address {
                        update(input: $input) {
                           note
                           validFrom
                           address {
                            ${addressFrag}
                           }
                       }
                    }
                }
            `,
            { input },
        );
    }

    deleteAddress(id: number) {
        return this.mutate<OkObject>(`
                mutation {
                    address {
                        delete(input: {
                            id: ${id}
                        }) {
                            ok
                        }
                    }
                }
            `);
    }

    createBasicInfo(input: CreateBasicInfoInput) {
        return this.mutate<BasicInfoObject>(
            `
                mutation($input: CreateBasicInfoInput!) {
                    basicInfo {
                        create(input: $input) {
                            ${basicInfoFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateBasicInfo(input: UpdateBasicInfoInput) {
        return this.mutate<BasicInfoObject>(
            `
                mutation($input: UpdateBasicInfoInput!) {
                    basicInfo {
                        update(input: $input) {
                            ${basicInfoFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteBasicInfo(id: number) {
        return this.mutate(`
            mutation {
                basicInfo {
                    delete(input: {
                        id: ${id}
                    }) {
                        ok
                    }
                }
            }
        `);
    }

    giveAccess(input: UserOrganizationAccessInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserOrganizationAccessInput!) {
                    organizationUser {
                        giveAccess(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    removeAccess(input: UserOrganizationAccessInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserOrganizationAccessInput!) {
                    organizationUser {
                        removeAccess(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }
}
