import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface MenuChangeEvent {
    key: string;
    routeEvent?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    menuSource$ = new Subject<MenuChangeEvent>();
    resetSource$ = new Subject();

    onMenuStateChange(event: MenuChangeEvent): void {
        this.menuSource$.next(event);
    }

    reset(): void {
        this.resetSource$.next(true);
    }
}
