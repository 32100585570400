import { MenuItem } from 'primeng/api';

export function isSubitemActive(items: MenuItem[], url: string): boolean {
    return items.some((subitem) => {
        if (url !== "/" && url.includes(subitem.routerLink)) {
            return true;
        } else if (subitem.items?.length) {
            return isSubitemActive(subitem.items, url);
        }
        return false;
    });
}