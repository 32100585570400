import { createFeatureSelector } from '@ngrx/store';
import { createInitialNotificationsState, NotificationsState } from './notifications.state';
import { SelectorFactory } from '../../../shared/store/common/selector.factory';

export const selectNotificationsState =
    createFeatureSelector<NotificationsState>('notifications');

const factory = new SelectorFactory(selectNotificationsState, createInitialNotificationsState()).create();

export const selectNotificationTypes = factory.notificationTypes;
export const selectNotifications = factory.notifications;
