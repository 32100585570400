<p-sidebar
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="w-full sm:w-18rem"
>
  <div class="px-2">
    <h5>Content</h5>
  </div>
</p-sidebar>
