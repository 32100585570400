<ng-container>
  <div *ngIf="root && item.visible" class="layout-menuitem-root-text">
    {{ item.label }}
  </div>
  <a
    *ngIf="(!item.routerLink || item.items) && item.visible"
    [attr.href]="item.url"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item.styleClass"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label ? item.label : ''"
    [tooltipDisabled]="!(isCompact && root && !active)"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>
  <a
    *ngIf="item.routerLink && !item.items && item.visible"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item.styleClass"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="
      item.routerLinkActiveOptions || {
        paths: 'exact',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored'
      }
    "
    [fragment]="item.fragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [preserveFragment]="item.preserveFragment"
    [skipLocationChange]="item.skipLocationChange"
    [replaceUrl]="item.replaceUrl"
    [state]="item.state"
    [queryParams]="item.queryParams"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label ? item.label : ''"
    [tooltipDisabled]="!(isCompact && root)"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>

  <ul
    *ngIf="item.items && item.visible"
    [@children]="submenuAnimation"
  >
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li
        app-menu-item
        [item]="child"
        [index]="i"
        [parentKey]="key"
        [class]="child.badgeStyleClass ?? ''"
        [ngClass]="{'active-menuitem': isItemActive(child)}"
      ></li>
    </ng-template>
  </ul>
</ng-container>
