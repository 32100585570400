import { SetSelectedOrganizationInput } from '@libs/types/src/user/input-type/set-selected-organization.input';
import { Observable } from "rxjs";
import { GqlService } from "../modules/content/service/gql.service";
import { OkObject } from "@it2go/types";

export class SettingsService extends GqlService {
    setSelectedOrganizationId(input: SetSelectedOrganizationInput): Observable<OkObject> {
        return this.mutate<OkObject>(`
            mutation ($input: SetSelectedOrganizationInput!) {
                user {
                    setSelectedOrganizationId(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    getSelectedOrganizationId(): Observable<number | null> {
        return this.query<number | null>(`
            query  {
                user {
                    getSelectedOrganizationId
                }
            }
        `);
    }
}
