import { FilterInput, GridInput } from '@it2go/data-grid';
import { singlePageFilterInput } from '../../shared/helpers/grid.builder';
import { isDate, momentDate } from '../../shared/helpers/date.helper';

export type KeyHints = 'search' | 'limit' | 'sort' | string | any;

export class FilterBuilder {
    private _filter: GridInput = {
        filter: [],
        sorter: [],
    };

    where(column: string, value: any, operator: string = 'EQ') {
        let values;
        if (!Array.isArray(value)) {
            values = [value.toString()];
        } else {
            values = value.map((it) => it.toString());
        }

        let op = operator;
        let field = column;
        ['IN', 'LIKE'].find((it) => {
            const ends = `_${it}`;
            if (field.endsWith(ends)) {
                field = field.slice(0, -ends.length);
                op = it;
                return true;
            }
            return false;
        });

        this._filter.filter!.push([
            {
                column: field,
                value: values,
                operator: op,
            },
        ]);

        return this;
    }

    personId(personId: string | number) {
        this.where('personId', personId.toString());

        return this;
    }

    search(search: string[] | number[] | string | number | null | undefined, splitBySpaces: boolean = false, detectDate: boolean = false) {
        if (search) {
            if (splitBySpaces && typeof search === 'string') {
                search = search.trim().split(/\s+/);
            }
            if (!Array.isArray(search)) {
                search = [(search as string | number).toString()];
            }
            if (detectDate) {
                search.forEach((s) => {
                    // If date is detected - use filter instead of search
                    s = s.toString();
                    if (s && isDate(s)) {
                        this.setDateFilter(momentDate(s).toISOString());
                        (search as string[]).splice((search as string[]).indexOf(s), 1);
                    }
                });
            }
            this._filter.search = search.map(s => s.toString());
        }

        return this;
    }

    serviceId(serviceId: string | number) {
        this.where('serviceId', serviceId.toString());

        return this;
    }

    organizationId(organizationId: string | number) {
        this.where('organizationId', organizationId.toString());

        return this;
    }

    sort(column: string, direction: 'ASC' | 'DESC' = 'ASC') {
        this._filter.sorter?.push({
            column,
            direction,
        });

        return this;
    }

    limit(limit: number) {
        this._filter.paging = {
            page: this._filter?.paging?.page ?? 1,
            itemsPerPage: limit,
        };

        return this;
    }

    page(page: number) {
        this._filter.paging = {
            page,
            itemsPerPage: this._filter?.paging?.itemsPerPage ?? 10,
        };

        return this;
    }

    withFilter(filter: FilterInput[][] | null) {
        if (filter) {
            this._filter.filter = filter;
        }
        return this;
    }

    create() {
        return this._filter;
    }

    input() {
        return {
            input: this._filter,
        };
    }

    filter() {
        return this._filter;
    }

    singlePage() {
        return singlePageFilterInput(this._filter).input;
    }

    singlePageInput() {
        return singlePageFilterInput(this._filter);
    }

    static singlePageInput(
        simpleEqs: Record<KeyHints, string | number | any> = {},
    ) {
        const builder = new FilterBuilder();
        builder.fillEqFilters(simpleEqs);

        return builder.singlePageInput();
    }

    static singlePage(simpleEqs: Record<KeyHints, string | number | any>) {
        return FilterBuilder.singlePageInput(simpleEqs).input;
    }

    static input(simpleEqs: Record<KeyHints, string | number | any>) {
        const builder = new FilterBuilder();
        builder.fillEqFilters(simpleEqs);

        return builder.input();
    }

    static filter(simpleEqs: Record<KeyHints, string | number | any>) {
        const builder = new FilterBuilder();
        builder.fillEqFilters(simpleEqs);

        return builder._filter;
    }

    static singleItemInput(simpleEqs: Record<KeyHints, string | number | any>) {
        const builder = new FilterBuilder();
        builder.fillEqFilters(simpleEqs);
        builder.limit(1);

        return builder.input();
    }

    static singleItem(simpleEqs: Record<KeyHints, string | number | any>) {
        const builder = new FilterBuilder();
        builder.fillEqFilters(simpleEqs);
        builder.limit(1);

        return builder._filter;
    }

    private fillEqFilters(simpleEqs: Record<KeyHints, string | number | any>) {
        Object.keys(simpleEqs).forEach((key) => {
            switch (key) {
                case 'limit':
                    this.limit(Number(simpleEqs[key]));
                    break;
                case 'page':
                    this.page(Number(simpleEqs[key]));
                    break;
                case 'search':
                    this.search(simpleEqs[key]);
                    break;
                case 'sort': {
                    const sorter = simpleEqs[key];
                    if (Array.isArray(sorter)) {
                        sorter.forEach((sort) => {
                            this.sort(sort.column, sort.direction || 'ASC');
                        });
                    } else {
                        this.sort(sorter.column, sorter.direction || 'ASC');
                    }
                    break;
                }
                default:
                    this.where(key, simpleEqs[key].toString());
            }
        });
    }

    private setDateFilter(date: Date | string) {
        // Set to the beginning of the day
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0);

        // Set to the beginning of the next day
        const endOfDay = new Date(date);
        endOfDay.setDate(endOfDay.getDate() + 1);
        endOfDay.setHours(0, 0, 0, 0);

        this.where('date', [startOfDay.toISOString(), endOfDay.toISOString()], 'BETWEEN');
    }
}
