import { serviceUserFrag } from './user.types';

export const medicalProcedureFrag = `
    code
    description
    duration
`;

export const medicalProcedureFromActiveVoucherFrag = `
    code
    description
    usualNumberOfApplications
    voucherId
    doctorId
    doctor {
        ${serviceUserFrag}
    }
`;
