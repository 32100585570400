import { AuditLogGridItemObject } from '@libs/types/src/object-history/object-type/audit-log.object';
import { ParsedAuditLog } from './audit-log-tree.types';

export function parseAuditLogHistory(items: AuditLogGridItemObject[], expanded: boolean = false) {
    const res: Record<number, ParsedAuditLog> = {};
    const latestChanges: any = {};
    let key = 0;
    let entityName = '';

    items.forEach((item: AuditLogGridItemObject) => {
        entityName = item.entityName;
        const data = JSON.parse(item.data);
        const oldData = JSON.parse(item.oldData);
        const changedFields = JSON.parse(item.changedFields);
        const parsed = <ParsedAuditLog>{
            count: 0,
            alteredLeaves: [],
            tree: [],
            data,
            changedFields: [],
        };

        const count = (obj: object) => {
            Object.entries(obj).forEach(([key, it]) => {
                if (it === true) {
                    parsed.count++;
                    parsed.alteredLeaves.push(key);
                }
                if (typeof it === 'object') count(it);
            });
        };
        count(changedFields);

        const subParse = (d: any[]) => {
            return d.map((it) => {
                if (typeof it === 'object') {
                    if (Array.isArray(it)) {
                        return JSON.stringify(it);
                    } else {
                        return Object.entries(it).map(([key, value]) => {
                            return {
                                _key: `${item.entityName}.fields.${key}`,
                                _value: value,
                            };
                        });
                    }
                } else {
                    return it;
                }
            });
        };

        const parse = (obj: object, fields: object[], latest: any, data: any, oldData: any) => {
            Object.entries(obj).forEach(([it, value]) => {
                fields.push({
                    key: key++,
                    label: it,
                    data: data[it],
                    oldData: oldData[it],
                    isArray: false,
                    expanded,
                    children: [],
                });
                const isLatest = !(latest[it] && latest[it].timestamp > item.timestamp);
                if (isLatest) {
                    latest[it] = {
                        data: data[it],
                        timestamp: item.timestamp,
                        authorName: item.authorName,
                        children: {},
                    };
                }
                if (typeof value === 'object') {
                    (fields[fields.length - 1] as any).data = '';
                    (fields[fields.length - 1] as any).oldData = '';
                    if (isLatest) latest[it].data = '';
                    parse(value, (fields[fields.length - 1] as any).children, latest[it].children, data[it] || {}, oldData[it] || {});
                }

                const d = (fields[fields.length - 1] as any).data;
                const dd = (fields[fields.length - 1] as any).oldData;
                if (typeof d === 'object') {
                    if (Array.isArray(d)) {
                        (fields[fields.length - 1] as any).isArray = true;
                        if (isLatest) latest[it].isArray = true;
                        (fields[fields.length - 1] as any).data = subParse(d).sort();
                        (fields[fields.length - 1] as any).oldData = subParse(dd || []).sort();

                    } else {
                        (fields[fields.length - 1] as any).data = JSON.stringify(d);
                        (fields[fields.length - 1] as any).oldData = JSON.stringify(dd);
                    }
                    if (isLatest) latest[it].data = (fields[fields.length - 1] as any).data;
                }
            });
        };
        parse(changedFields, parsed.tree as any, latestChanges, data, oldData);
        res[item.id] = parsed;
    });

    const toTree = (obj: Record<string, any>): any => {
        return Object.entries(obj || {}).map(([field, value]) => {
            return {
                key: key++,
                label: field,
                data: value.data,
                expanded: true,
                children: toTree(value.children),
                timestamp: value.timestamp,
                authorName: value.authorName,
                isArray: value.isArray,
            };
        });
    };

    return {
        entityName,
        parsed: res,
        summary: toTree(latestChanges),
    };
}
