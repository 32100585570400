import { Injectable } from '@angular/core';
import { OkObject } from '@it2go/types';
import { Observable } from 'rxjs';
import { GridInput } from '@it2go/data-grid';
import { historyGridItemFrag } from './types/history-item.types';
import { GqlService } from '../modules/content/service/gql.service';
import {
    HistoryItemCommentUpdateInput,
} from '@libs/types/src/object-history/input-type/history-item-comment-update.input';
import { filterFrag } from './types/filter.types';

@Injectable()
export class ObjectHistoryService extends GqlService {

    getHistory(input: GridInput | null = null): Observable<OkObject> {
        return this.query(
            `
                query($input: GridInput) {
                    auditLog {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${historyGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateHistoryComment(input: HistoryItemCommentUpdateInput) {
        return this.mutate(
            `
                mutation($input: HistoryItemCommentUpdateInput!) {
                    auditLog {
                        updateComment(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
