import { Injectable } from '@angular/core';
import { CollectionGridObject, OkObject } from '@it2go/types';
import { Observable } from 'rxjs';
import { GridInput } from '@it2go/data-grid';
import { CollectionDeleteInput } from '@libs/types/src/chcrm/input-type/collection/collection-delete.input';
import {
    collectionFrag,
    collectionGridItemFrag,
    collectionStatistic,
    collectionStatistics,
} from './types/collection.types';
import { CollectionGetInput } from '@libs/types/src/chcrm/input-type/collection/collection-get.input';
import { CollectionObject } from '@libs/types/src/chcrm/object-type/collection/collection.object';
import { CollectionUpdateInput } from '@libs/types/src/chcrm/input-type/collection/collection-update.input';
import { GqlService } from '../modules/content/service/gql.service';
import { filterFrag } from './types/filter.types';
import {
    CollectionGetStatisticsInput,
    CollectionsGetStatisticsInput,
} from '@libs/types/src/chcrm/input-type/collection/collections-get-statistics.input';
import {
    CollectionStatisticObject,
    CollectionStatisticsObject,
} from '@libs/types/src/chcrm/object-type/collection/collection-statistics.object';

@Injectable()
export class CollectionService extends GqlService {

    deleteCollection(input: CollectionDeleteInput): Observable<OkObject> {
        return this.mutate(
            `
                mutation($input: CollectionDeleteInput!) {
                    collection {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getCollections(input: GridInput | null) {
        return this.query<CollectionGridObject>(
            `
                query($input: GridInput) {
                    collection {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${collectionGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateCollection(input: CollectionUpdateInput) {
        return this.mutate(
            `
                mutation($input: CollectionUpdateInput!) {
                    collection {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getCollection(input: CollectionGetInput) {
        return this.query<CollectionObject>(
            `
                query($input: CollectionGetInput!) {
                    collection {
                        get(input: $input) {
                            ${collectionFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getCollectionStatistics(input: CollectionGetStatisticsInput) {
        return this.query<CollectionStatisticObject>(
            `
                query($input: CollectionGetStatisticsInput!) {
                    collection {
                        statistic(input: $input) {
                            ${collectionStatistic}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getCollectionsStatistics(input: CollectionsGetStatisticsInput) {
        return this.query<CollectionStatisticsObject[]>(
            `
                query($input: CollectionsGetStatisticsInput!) {
                    collection {
                        statistics(input: $input) {
                            ${collectionStatistics}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
