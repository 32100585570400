
export type OrganizationSettingsSystemSettingsType = {
    topbarVideocall: boolean;
    topbarFullscreen: boolean;
    topbarSearch: boolean;
    topbarHelp: boolean;

    topbarUserEmail: boolean;
    topbarUserOffice: boolean;
    topbarUserReports: boolean;
    topbarUserReadMessages: boolean;
};

export function createDefaultOrganizationSystemSettings(): OrganizationSettingsSystemSettingsType {
    return {
        topbarVideocall: false,
        topbarFullscreen: false,
        topbarSearch: true,
        topbarHelp: false,

        topbarUserEmail: false,
        topbarUserOffice: false,
        topbarUserReports: false,
        topbarUserReadMessages: false,
    };
}