import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    static default = 'light-blue';
    static defaultFloatLabel = 'md-float-label';

    private readonly style: HTMLLinkElement;
    private readonly floatLabelStyle: HTMLLinkElement;

    constructor() {
        this.style = document.createElement('link');
        this.style.rel = 'stylesheet';

        this.floatLabelStyle = document.createElement('link');
        this.floatLabelStyle.rel = 'stylesheet';

        if (localStorage.getItem('floatLabelTheme') !== undefined) {
            this.floatLabelStyle.href = `/${this.floatLabelScheme}.css`;
            document.head.appendChild(this.floatLabelStyle);
        }

        document.head.appendChild(this.style);

        if (localStorage.getItem('theme') !== undefined) {
            this.style.href = `/${this.colorScheme}.css`;
        }
    }

    get colorScheme(): string {
        const theme = localStorage.getItem('theme') ?? ThemeService.default;

        return theme.split('-')[0];
    }

    set colorScheme(value: string) {
        localStorage.setItem('theme', value);
        this.style.href = `/${value}.css`;
    }

    get floatLabelScheme(): string {
        return localStorage.getItem('floatLabelTheme') ?? ThemeService.defaultFloatLabel;
    }

    set floatLabelScheme(value: string) {
        localStorage.setItem('floatLabelTheme', value);
        this.floatLabelStyle.href = `/${value}.css`;
    }
}
