import {
    createDefaultOrganizationSystemSettings,
    OrganizationSettingsSystemSettingsType,
} from './../../../../../../../types/src/organization/types/organization-settings/organization-settings-system-settings.type';
import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';
import { AuthorizationService } from '../../../../services/authorization.service';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { OrganizationSettingTypeEnum } from '@libs/types/src/organization/enum/organization-setting-type.enum';
import {
    selectCurrentActiveIntegration,
} from 'packages/client/src/app/store/global/global.selectors';

interface State {}

const defaultState = {};

@Injectable()
export class TopbarStore extends ComponentStoreFactory<State> {
    systemSettings$ = this.globalStore!.select(
        selectCurrentActiveIntegration(OrganizationSettingTypeEnum.SYSTEM_SETTINGS),
    ).pipe(
        map((it) =>
            it?.data
                ? (JSON.parse(it.data) as OrganizationSettingsSystemSettingsType)
                : createDefaultOrganizationSystemSettings(),
        ),
    );

    constructor(
        svc: AuthorizationService,
        store: Store,
    ) {
        super(defaultState, store, svc);
    }
}
