import { Injectable } from '@angular/core';
import { GqlService } from '../modules/content/service/gql.service';
import {
    ExpertiseAllowedStatusObject,
} from '@libs/types/src/services/object-type/service/expertise-allowed-status.object';
import { expertiseAllowedStatusFrag } from './types/expertise.types';

@Injectable()
export class ExpertiseGqlService extends GqlService {

    public getExpertiseAllowedStatuses() {
        return this.query<ExpertiseAllowedStatusObject[]>(
            `
                query {
                    expertise {
                        allowedStatuses {
                            ${expertiseAllowedStatusFrag}
                        }
                    }
                }
            `,
        );
    }

}
