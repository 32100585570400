import { GridInput } from '@it2go/data-grid';

export function singlePageFilter(filter: GridInput = {}) {
    return {
        ...filter,
        paging: {
            page: 1,
            // Pls do not change, setting this to high number fucks up the application.
            // Let the user supply more specific filter.
            itemsPerPage: 50,
        },
    };
}

export function singlePageFilterInput(filter: GridInput = {}) {
    return { input: singlePageFilter(filter) };
}

export function singleItem(filter: GridInput = {}) {
    return {
        ...filter,
        paging: {
            page: 1,
            itemsPerPage: 1,
        },
    };
}

export function singleItemInput(filter: GridInput = {}) {
    return { input: singleItem(filter) };
}

export function singleFilter(
    column: string,
    value: any,
    operator = 'EQ',
): GridInput {
    return {
        filter: [
            [
                {
                    column,
                    operator: 'EQ',
                    value: [value],
                },
            ],
        ],
    };
}

export function singleFilterInput(
    column: string,
    value: unknown,
    operator = 'EQ',
): { input: GridInput } {
    return { input: singleFilter(column, value, operator) };
}
