<div class="layout-wrapper" [ngClass]="containerClass" [class]="sidebarClass">
  <div class="layout-content-wrapper">
    <app-topbar></app-topbar>
    <app-breadcrumb></app-breadcrumb>

    <div class="layout-content">
      <ng-container [ngTemplateOutlet]="submenuContent"></ng-container>
      <ng-container [ngTemplateOutlet]="subsubmenuContent"></ng-container>
      <div class="layout-content-main">
        <ng-content select="[main-panel]"></ng-content>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
  <app-config></app-config>
  <app-notifications></app-notifications>
  <app-search></app-search>
  <app-right-menu></app-right-menu>
</div>
