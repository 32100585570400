export const knownRouteParameters = [
    'organizationId',
    'centerId',
    'folderId',
    'voucherId',
    'serviceId',
    'id',
    'insuranceAbbr',
    'invoiceId',
    'batchId',
    'documentId',
    'billingId',
    'workplaceId',
] as const;

export type knownRouteParametersKey = typeof knownRouteParameters[number];
