import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breadcrumbSecondPart',
  standalone: true,
})
export class BreadcrumbSecondPartPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return '';
    }
    const secondPart = value.split('|')[1];
    if (!secondPart) {
      return '';
    }
    return secondPart;
  }
}
