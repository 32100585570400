import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breadcrumbFirstPart',
  standalone: true,
})
export class BreadcrumbFirstPartPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return '';
    }
    return value.split('|')[0];
  }
}
