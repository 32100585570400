import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NotificationGridItemObject, VisitHistoryObject } from '@it2go/types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApolloModule } from 'apollo-angular';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import { LayoutService } from '../../services/layout.service';
import {
    deleteNotification,
    getNotifications,
    getNotificationTypes,
    readNotification,
    receiveNotification,
} from '../../store/notifications/notifications.actions';
import { selectNotifications, selectNotificationTypes } from '../../store/notifications/notifications.selectors';
import { HistoryGroup, NotificationsStore } from './notifications.store';
import { map } from 'rxjs/operators';
import { FilterBuilder } from '../../../content/helper/filter.builder';
import { whoami } from '../../../../store/global/global.actions';
import { selectCurrentUser } from '../../../../store/global/global.selectors';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        SidebarModule,
        AccordionModule,
        TranslateModule,
        ApolloModule,
        AvatarModule,
        DividerModule,
        ButtonModule,

    ],
    providers: [
        NotificationsStore,
    ],
})
export class NotificationsComponent extends SubscriptionComponent implements OnInit {

    notificationTypes$: Observable<string[]> = this.store.globalStore!.select(selectNotificationTypes);

    notificationsByTypes$: Observable<Record<string, NotificationGridItemObject[]>> = this.store.globalStore!.select(selectNotifications).pipe(map((notifications) => {
        const types: Record<string, NotificationGridItemObject[]> = {};
        if (notifications) {
            (notifications['items']).forEach((it: NotificationGridItemObject) => {
                types[it.type] = types[it.type] ?? [];
                types[it.type].push(it);
            });
        }

        return types;
    }));

    constructor(
        public router: Router,
        public layoutService: LayoutService,
        protected readonly store: NotificationsStore,
        private readonly breadcrumb: BreadcrumbService,
        private readonly translate: TranslateService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.store.globalStore!.dispatch(getNotificationTypes());
        this.store.globalStore!.dispatch(getNotifications(
            FilterBuilder.input({
                sort: {
                    column: 'createdAt',
                    direction: 'DESC',
                },
            }),
        ));
        this.subs.push(
            this.store.globalStore!.select(selectCurrentUser).subscribe((user) => {
                if (user) {
                    this.store.globalStore!.dispatch(receiveNotification({ user }));
                }
            }),
        );
    }

    navigate(link: VisitHistoryObject) {
        if (link.url) {
            this.router.navigateByUrl(link.url, { state: { fromHistory: true } });
        }
    }

    translateHeader(group: HistoryGroup) {
        const link = group.items[0];
        const data = JSON.parse(link.data || '{}');

        const parts = link.url!
            .split('/')
            .slice(0, group.match + 2)
            .filter((it) => it);

        return parts.map((it, index) => {
            const value = data[index] || it;
            if (['__persons'].includes(value) && index < (parts.length - 1)) return '';

            if (value.startsWith('__')) {
                return this.translate.instant(`breadcrumbs.${value.slice(2)}`);
            } else {
                return value;
            }
        })
            .slice(1)
            .join(' ');
    }

    // TODO tahle metoda se volá pořád dokola - nějaká PrimeNg accordion sračka co nutí neustále re-render
    translateLink(match: number, link: VisitHistoryObject) {
        const data = JSON.parse(link.data || '{}');

        const parts = link.url!
            .split('/')
            .filter((it) => it);

        return parts.map((it, index) => {
            const value = data[index] || it;
            if (['__persons'].includes(value) && index < (parts.length - 1)) return '';

            if (value.startsWith('__')) {
                return this.translate.instant(`breadcrumbs.${value.slice(2)}`);
            } else {
                return value;
            }
        })
            .slice((match + 1) || 1)
            .filter((it) => it);
    }

    get visible(): boolean {
        return this.layoutService.state.notificationsVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.notificationsVisible = _val;
    }

    getInitials(name: string) {
        return name
            .split(' ')
            .map((x) => x[0])
            .join('');
    }

    handleReadStatus(notif: NotificationGridItemObject, event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.store.globalStore!.dispatch(readNotification({ input: { read: true, id: notif.id } }));
    }

    handleRemove(notif: NotificationGridItemObject, event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.store.globalStore!.dispatch(deleteNotification({ input: { id: notif.id } }));
    }

    getUnreadCount(notifs: NotificationGridItemObject[]) {
        return notifs.reduce((acc, it) => acc + (it.isRead ? 0 : 1), 0);
    }

}
