import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { CreateVisitHistoryInput, VisitHistoryGridItemObject, VisitHistoryObject } from '@it2go/types';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GqlService } from '../modules/content/service/gql.service';
import { EventObject } from '@libs/types/src/notifications/object-type/event.object';

@Injectable()
export class NavigatorService extends GqlService {

    constructor(
        apollo: Apollo,
    ) {
        super(apollo);
    }

    getVisitHistory() {
        return this.query<VisitHistoryGridItemObject[]>(
            `
                query {
                    visitHistory {
                        list {
                            url
                            data
                            userId
                        }
                    }
                }
            `,
        );
    }

    createVisitHistory(input: CreateVisitHistoryInput) {
        return this.mutate<VisitHistoryObject>(
            `
                mutation($input: CreateVisitHistoryInput!) {
                    visitHistory {
                        create(input: $input) {
                            url
                        }
                    }
                }
            `,
            { input },
        );
    }

    visitHistorySubscription(userId: string): Observable<VisitHistoryObject> {
        return this.apollo.subscribe<{ visitHistory: VisitHistoryObject }>({
            query: gql`
            subscription($userId: String!) {
                visitHistory(userId: $userId) {
                    url
                    data
                    userId
                }
            }
        `,
            variables: { userId },
        })
            .pipe(map((res) => res.data!.visitHistory));
    }

    eventSubscription(): Observable<EventObject> {
        return this.apollo.subscribe<EventObject>({
            query: gql`
                subscription {
                    events {
                        name
                    }
                }
            `,
        })
            .pipe(map((res) => res.data!));
    }

}
