import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    CreateUserInput,
    OkObject,
    UpsertUserTableInput,
    UserGridObject,
    UserIdInput,
    UserObject,
    UserRoleGridObject,
    UserTableGridObject,
    UserTableObject,
} from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { userTableFrag, userTableGridItemFrag } from './types/user-table.types';
import { filterFrag } from './types/filter.types';
import { userFrag, userGridItemFrag, userRoleGridItemFrag } from './types/user.types';
import { UserUpdateInput } from '@libs/types/src/user/input-type/user/user-update.input';
import { gql } from '@apollo/client/core';
import { map } from 'rxjs';
import { ChangePasswordInput } from '@libs/types/src/user/input-type/change-password.input';

@Injectable()
export class UserService extends GqlService {

    getUsers(input: GridInput | null) {
        return this.query<UserGridObject>(
            `
                query($input: GridInput) {
                    user {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateUser(input: UserUpdateInput) {
        return this.mutate<UserGridObject>(
            `
                mutation($input: UserUpdateInput!) {
                    user {
                        update(input: $input) {
                            ${userFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getUserTables(input: GridInput | null) {
        return this.query<UserTableGridObject>(
            `
                query($input: GridInput) {
                    userTable {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userTableGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createUser(input: CreateUserInput) {
        return this.apollo.mutate<{ user: { create: UserObject } }>({
            mutation: gql`
                mutation($input: CreateUserInput!) {
                    user {
                        create(input: $input) {
                            id
                            email
                            name
                            surname
                            titleBeforeName
                            titleAfterName
                        }
                    }
                }
            `,
            variables: { input },
        })
            .pipe(map((result) => result.data!.user.create));
    }

    upsertUserTable(input: UpsertUserTableInput) {
        return this.mutate<UserTableObject>(
            `
                mutation($input: UpsertUserTableInput!) {
                    userTable {
                        upsert(input: $input) {
                            ${userTableFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public changePassword(input: ChangePasswordInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ChangePasswordInput!) {
                    user {
                        changePassword(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getUserRoles(input: GridInput | null = null) {
        return this.query<UserRoleGridObject>(
            `
                query($input: GridInput) {
                    userRole {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userRoleGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public generateUserPassword(input: UserIdInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserIdInput!) {
                    user {
                        generatePassword(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
