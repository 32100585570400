import { Injectable } from '@angular/core';
import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';
import { Store } from '@ngrx/store';
import {
    CollectionGridItemObject,
    CollectionGridObject,
    DonationGridItemObject,
    DonationGridObject,
    DonorGridItemObject,
    DonorGridObject,
} from '@it2go/types';
import { DonorService } from '../../../../services/donor.service';
import { map } from 'rxjs/operators';
import { DonationService } from '../../../../services/donation.service';
import { FoundItem } from './search.types';
import { FilterBuilder } from '../../../content/helper/filter.builder';
import { GridInput } from '@it2go/data-grid';
import { CollectionService } from '../../../../services/collection.service';
import { ObjectHistoryService } from '../../../../services/object-history.service';
import {
    HistoryItemGridItemObject,
    HistoryItemGridObject,
} from '@libs/types/src/object-history/object-type/history-item.object';
import { parseAuditLogHistory } from '../../../shared/components/audit-log-tree/audit-log-tree.parser';

interface State {
    results: FoundItem[],
    historyResults: FoundItem[],
}

const defaultState: State = {
    results: [],
    historyResults: [],
};

@Injectable()
export class SearchStore extends ComponentStoreFactory<State> {

    public isDonorLast = false;
    public isDonationLast = false;
    public isCollectionLast = false;
    public isHistoryLast = false;
    public loading = 0;
    public currHistoryPage = 0;
    public currDonorPage = 0;
    public currDonationPage = 0;
    public currCollectionPage = 0;

    onlyDonors$ = this.data.results$.pipe(
        map((it) => it.filter((i) => i.entity === 'donor')),
    );
    onlyDonations$ = this.data.results$.pipe(
        map((it) => it.filter((i) => i.entity === 'donation')),
    );
    onlyCollections$ = this.data.results$.pipe(
        map((it) => it.filter((i) => i.entity === 'collection')),
    );
    onlyHistory$ = this.data.historyResults$;

    constructor(
        svc: DonorService,
        donationSvc: DonationService,
        collectionSvc: CollectionService,
        historySvc: ObjectHistoryService,
        store: Store,
    ) {
        super(defaultState, store, svc);
        this.addApi(donationSvc);
        this.addApi(collectionSvc);
        this.addApi(historySvc);
        this.subs.push(
            this.apiSuccess.getSearchDonors.subscribe(async (it: DonorGridObject) => {
                this.loading--;
                this.isDonorLast = it['paging'].page === it['paging'].lastPage;
                await this.addResults(
                    this.currDonorPage <= 1,
                    (it['items'] || []).map((donor: DonorGridItemObject) => (<FoundItem>{
                        entity: 'donor',
                        data: donor,
                        date: donor.createdAt,
                    })),
                );
            }),
            this.apiSuccess.getDonations.subscribe(async (it: DonationGridObject) => {
                this.loading--;
                this.isDonationLast = it['paging'].page === it['paging'].lastPage;
                await this.addResults(
                    this.currDonationPage <= 1,
                    (it['items'] || []).map((donation: DonationGridItemObject) => (<FoundItem>{
                        entity: 'donation',
                        data: donation,
                        date: donation.receivedAt,
                    })),
                );
            }),
            this.apiSuccess.getCollections.subscribe(async (it: CollectionGridObject) => {
                this.loading--;
                this.isCollectionLast = it['paging'].page === it['paging'].lastPage;
                await this.addResults(
                    this.currCollectionPage <= 1,
                    (it['items'] || []).map((collection: CollectionGridItemObject) => (<FoundItem>{
                        entity: 'collection',
                        data: collection,
                        date: collection.createdAt,
                    })),
                );
            }),
            this.apiSuccess.getHistory.subscribe(async (it: HistoryItemGridObject) => {
                this.loading--;
                this.isHistoryLast = it['paging'].page === it['paging'].lastPage;
                const { parsed } = parseAuditLogHistory(it['items'] || [], false);
                this.patchState({
                    historyResults: [
                        ...await this.get(this.data.historyResults$),
                        ...(it['items'] || []).map((history: HistoryItemGridItemObject) => (<FoundItem>{
                            entity: 'history',
                            data: history,
                            date: history.timestamp,
                            tree: parsed[history.id],
                        })),
                    ],
                });
            }),
        );
    }

    public nextPage(entity: string, _search: string, lazyLoaded: boolean = false): void {
        const search = _search.replaceAll('@', ' ').replaceAll('.', ' ');
        if (!this.isDonorLast && ['donor', 'all'].includes(entity)) {
            if (lazyLoaded && this.currDonorPage > 0) return;
            this.loading++;
            this.api.getSearchDonors(this.filterInput(++this.currDonorPage, search));
        }
        if (!this.isDonationLast && ['donation', 'all'].includes(entity)) {
            if (lazyLoaded && this.currDonationPage > 0) return;
            this.loading++;
            this.api.getDonations(this.filterInput(++this.currDonationPage, search, 'receivedAt'));
        }
        if (!this.isCollectionLast && ['collection', 'all'].includes(entity)) {
            if (lazyLoaded && this.currCollectionPage > 0) return;
            this.loading++;
            this.api.getCollections(this.filterInput(++this.currCollectionPage, search));
        }
        if (!this.isHistoryLast && ['history'].includes(entity)) {
            if (lazyLoaded && this.currHistoryPage > 0) return;
            this.loading++;
            this.api.getHistory(
                (new FilterBuilder())
                    .limit(50)
                    .page(++this.currHistoryPage)
                    .search(search)
                    .sort('timestamp', 'DESC')
                    .where('changedFields', '{}', 'NEQ')
                    .filter()
            );
        }
    }

    public clear() {
        this.loading = 0;
        this.currDonorPage = 0;
        this.currDonationPage = 0;
        this.currCollectionPage = 0;
        this.currHistoryPage = 0;
        this.isDonorLast = false;
        this.isDonationLast = false;
        this.isCollectionLast = false;
        this.isHistoryLast = false;
        this.patchState({ results: [], historyResults: [] });
    }

    private filterInput(page: number, search: string, orderColumn: string = 'createdAt'): GridInput {
        return FilterBuilder.filter({
            search: search,
            sort: { column: orderColumn, direction: 'DESC' },
            page,
            limit: 50,
        });
    }

    private async addResults(merge: boolean, results: FoundItem[]): Promise<void> {
        if (merge) {
            this.patchState({
                results: [
                    ...await this.get(this.data.results$),
                    ...results,
                ].sort((a, b) => a.date < b.date ? 1 : -1),
            });
        } else {
            this.patchState({
                results: [
                    ...await this.get(this.data.results$),
                    ...results,
                ],
            });
        }
    }

}
