import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { GroupGridObject } from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { filterFrag } from './types/filter.types';
import { groupGridItemFrag } from './types/acl.types';

@Injectable()
export class AclService extends GqlService {

    getGroups(input: GridInput | null = null) {
        return this.query<GroupGridObject>(
            `
                query($input: GridInput) {
                    group {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${groupGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

}
