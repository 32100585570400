import { filter, Subject, take } from 'rxjs';
import { EntityEventMessengerEnum } from '../../../../../types/src/common/enum/entity-event-messenger.enum';

export const eventReceived = new Subject<string>();

export function onEvent(event: EntityEventMessengerEnum, callback: CallableFunction) {
    eventReceived
        .pipe(filter((it) => it === event))
        .pipe(take(1))
        .subscribe(() => {
            callback();
        });
}
