export const addressFrag = `
    name
    location
    position {
        lon
        lat
    }
    regionalStructure {
        name
        type
        isoCode
    }
    zip
`;
