export enum ResourceEnum {
    USER = 'user',
    ORGANIZATION = 'organization',
    SERVICE = 'service',
    PERSON = 'person',
    FOLDER = 'folder',
    FORM = 'form',
    DATABOX = 'databox',
    DONATION = 'donation',
    COLLECTION_STATISTICS = 'collectionStatistics',
}
