import moment from 'moment';

export function dateify(date?: string|null|Date) {
    return date ? new Date(date) : null;
}

export function dateifyOrNow(date?: string|null|Date) {
    return date ? new Date(date) : new Date();
}

export function timeify(date?: string|null|Date) {
    if (!date) return null;
    const dated = new Date(date);

    return `${dated.getHours()}:${dated.getMinutes().toString().padStart(2, '0')}`;
}

export function getLastDayOfMonth(date?: string|null|Date) {
    const dated = date? new Date(date) : new Date();
    const nextMonth = new Date(dated);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    return new Date(nextMonth.setDate(0));
}

export function isLastDayOfMonth(date?: string|null|Date) {
    const dated = date? new Date(date) : new Date();
    const nextDay = new Date(dated);
    nextDay.setDate(nextDay.getDate() + 1);

    return nextDay.getDate() === 1;
}

export function isDate(input: string | null | undefined, format: string[] = dateCzFormats) {
    if (!input) return false;
    return moment(input, format, true).isValid() as boolean;
}

export function momentDate(date: string | Date, format: string[] = dateCzFormats) {
    return moment(date, format);
}

export const dateCzFormats = [
    'D.M.YYYY',
    'D/M/YYYY',
    'D. M. YYYY',
    'D. M.', 'D.M.', 'D.M', 'D. M',
    'D.M.YY', 'D. M. YY'
]; 
