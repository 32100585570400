import { addressFrag } from "./common.types";

export const basicInfoFrag = `
    id
    name
    email
    dic
    ico
    validFrom
    validTo
    systemFrom
    systemTo
    phone
    web
`;

export const centerFrag = `
    id
    primary
    logo
    addresses {
        id
        address {
            ${addressFrag}
        }
        note
        type
        validFrom
        validTo
    }
    basicInfos {
        ${basicInfoFrag}
    }
    representativePersons {
        id
        name
        email
        note
        sex
        surname
        titleAfterName
        titleBeforeName
        validFrom
        validTo
        relationId
    }
    contactPersons {
        id
        name
        email
        note
        sex
        surname
        titleAfterName
        titleBeforeName
        validFrom
        validTo
        relationId
    }
`;

export const organizationFrag = `
    id
    centers {
        ${centerFrag}
    }
`;

export const organizationGridItemFrag = `
    id
    name
    address {
        ${addressFrag}
    }
    workersCount
    workingDaysCount
    sizeOfFiles
    sizeOfDatabase
    representativePerson {
        name
        surname
        titleBeforeName
        titleAfterName
    }
    contactPerson {
        name
        surname
        titleBeforeName
        titleAfterName
    }
    systemFrom
    systemTo
`;

export const personFrag = `
    email
    id
    name
    note
    relationId
    sex
    surname
    titleAfterName
    titleBeforeName
    validFrom
    validTo
`;

export const personGridItemFrag = `
    id
    name
    surname
    titleBeforeName
    titleAfterName
    email
    note
    sex
    validFrom
    validTo
`;
