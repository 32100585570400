import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FoundItem } from '../search.types';
import { stopPropagation } from 'ol/events/Event';
import { Inplace } from 'primeng/inplace';

@Component({
    selector: 'app-search-result',
    templateUrl: 'search-result.component.html',
    styleUrl: 'search-result.component.scss',
})
export class SearchResultComponent implements OnChanges {

    @Input() item!: FoundItem;
    @Input() withTag: boolean = false;
    @Output() clickChange = new EventEmitter();
    @ViewChild('historyTree') historyTree!: Inplace;

    protected severity = '';
    protected entityId = '';
    protected tagLabel = '';
    protected dateLabel = '';
    protected link: string | null = null;
    protected copied = false;

    constructor(
        private readonly router: Router,
    ) {
    }

    public ngOnChanges(): void {
        this.tagLabel = this.item.entity;
        this.entityId = this.item.data.id;
        if (this.item.entity === 'history') {
            this.tagLabel = this.item.data.entityName;
            this.entityId = this.item.data.entityId;
        }

        switch (this.tagLabel) {
            case 'donor':
                this.severity = 'primary';
                this.dateLabel = 'donor.fields.createdAt';
                this.link = `/crm/donors/${this.entityId}`;
                break;
            case 'donation':
                this.severity = 'danger';
                this.dateLabel = 'donation.fields.receivedAt';
                this.link = `/crm/donations/${this.entityId}`;
                break;
            case 'collection':
                this.severity = 'success';
                this.dateLabel = 'donation.fields.createdAt';
                this.link = `/crm/collections/${this.entityId}`;
                break;
            default:
        }
        if (this.item.entity === 'history') {
            this.dateLabel = '';
        }
    }

    protected copyId(event: any): void {
        this.copied = true;
        setTimeout(() => { this.copied = false }, 10);
        event.stopPropagation();
        navigator.clipboard.writeText(this.entityId);
    }

    protected goto() {
        if (!this.link) return;
        this.router.navigate([this.link]);
        this.clickChange.emit();
    }

    protected hideHistoryTree(event: any): void {
        this.historyTree?.deactivate(event);
    }

    protected readonly stopPropagation = stopPropagation;
}
