import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../../services/layout.service';
import { MenuService } from '../../services/menu.service';
import { isSubitemActive } from '../../../shared/helpers/menu-subitem-active';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-menu-item]',
  templateUrl: './menu-item.component.html',
  animations: [
    trigger('children', [
      state(
        'collapsed',
        style({
          height: '0',
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
        }),
      ),
      state(
        'hidden',
        style({
          display: 'none',
        }),
      ),
      state(
        'visible',
        style({
          display: 'block',
        }),
      ),
      transition(
        'collapsed <=> expanded',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
    ]),
  ],
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input({ required: true }) item!: MenuItem;
  @Input() index!: number;
  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;
  @Input() parentKey!: string;

  key = '';
  active = false;
  menuResetSubscription: Subscription;
  menuSourceSubscription: Subscription;

  constructor(
    public layoutService: LayoutService,
    private cd: ChangeDetectorRef,
    public router: Router,
    private menuService: MenuService,
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
      (value) => {
        Promise.resolve(null).then(() => {
          if (value.routeEvent) {
            this.active = !!(
              value.key === this.key || value.key.startsWith(`${this.key}-`)
            );
          } else if (
            value.key !== this.key &&
            !value.key.startsWith(`${this.key}-`) &&
            !this.key.startsWith(`${value.key}-`)
          ) {
            this.active = false;
          }
        });
      },
    );

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.isSlim || this.isHorizontal || this.isCompact) {
          this.active = false;
        } else if (this.item.routerLink) {
          this.updateActiveStateFromRoute();
        }
      });
  }

  @HostBinding('class.active-menuitem') get activeClass(): boolean {
    return this.active && !this.root;
  }

  get submenuAnimation(): string {
    if (
      this.layoutService.isDesktop() &&
      (this.layoutService.isHorizontal() ||
        this.layoutService.isSlim() ||
        this.layoutService.isCompact())
    ) {
      return this.active ? 'visible' : 'hidden';
    }

    if (this.root) {
      return 'expanded';
    }

    return this.active ? 'expanded' : 'collapsed';
  }

  get isHorizontal(): boolean {
    return this.layoutService.isHorizontal();
  }

  get isSlim(): boolean {
    return this.layoutService.isSlim();
  }

  get isCompact(): boolean {
    return this.layoutService.isCompact();
  }

  ngOnInit(): void {
    this.key = this.parentKey
      ? `${this.parentKey}-${this.index}`
      : String(this.index);

    if (
      !(this.isSlim || this.isHorizontal || this.isCompact) &&
      this.item.routerLink
    ) {
      this.updateActiveStateFromRoute();
    }
    this.isItemActive()
  }

  updateActiveStateFromRoute(): void {
    const activeRoute = this.router.isActive(
      this.item.routerLink[0],
      <IsActiveMatchOptions>this.item.routerLinkActiveOptions || {
        paths: 'exact',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored',
      },
    );

    if (activeRoute) {
      this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
    }
  }

  isItemActive(item = this.item): boolean {
    const active = this.router.url !== "/" && (this.router.url.includes(item.routerLink) || (!!item.items?.length && isSubitemActive(item.items, this.router.url)));
    if (!this.root && active) this.toggleActive(true)
    return active
  }

  itemClick(event: Event): void {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();

      return;
    }

    // navigate with hover
    if ((this.root && this.isSlim) || this.isHorizontal || this.isCompact) {
      this.layoutService.state.menuHoverActive =
        !this.layoutService.state.menuHoverActive;
    }

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    this.toggleActive();

    this.menuService.onMenuStateChange({ key: this.key });
  }

    toggleActive(active?: boolean) {
        if (this.item.items) {
            this.active = active ?? !this.active;

            if (
                this.root &&
                this.active &&
                (this.isSlim || this.isHorizontal || this.isCompact)
            ) {
                this.layoutService.onOverlaySubmenuOpen();
            }
        } else {
            if (this.layoutService.isMobile()) {
                this.layoutService.state.staticMenuMobileActive = false;
            }

            if (this.isSlim || this.isHorizontal || this.isCompact) {
                this.menuService.reset();
                this.layoutService.state.menuHoverActive = false;
            }
        }
    }

  onMouseEnter(): void {
    // activate item on hover
    if (
      this.root &&
      (this.isSlim || this.isHorizontal || this.isCompact) &&
      this.layoutService.isDesktop()
    ) {
      if (this.layoutService.state.menuHoverActive) {
        this.active = true;
        this.menuService.onMenuStateChange({ key: this.key });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
