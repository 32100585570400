import { NotificationGridObject } from '@it2go/types';

export const notificationsFeatureKey = 'notifications';

export interface NotificationsState {
    notifications: NotificationGridObject|null;
    notificationTypes: string[];
}

export const createInitialNotificationsState = (): NotificationsState => ({
    notifications: null,
    notificationTypes: [],
});
