import { Component } from '@angular/core';
import { BreadcrumbsEnum } from '../../../shared/models/breadcrumbs.enum';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    protected readonly BreadcrumbsEnum = BreadcrumbsEnum;
}
