// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ActionEnum, ResourceEnum } from '@it2go/types';
import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { ServiceService } from '../../../content/service/service.service';
import { PersonService } from '../../../content/service/person.service';
import { OrganizationService } from '../../../content/service/organization.service';
import { UserService } from '../../../content/service/user.service';
import { FileService } from '../../service/file.service';
import { DonorService } from '../../../crm/service/donor.service';
import { WarningService } from '../../../../services/warning.service';
import { DonationService } from '../../../../services/donation.service';
import { CollectionService } from '../../../../services/collection.service';
import { ExpectedDonationService } from '../../../../services/expected-donation.service';
import { WorkplaceService } from '../../../../services/workplace.service';
import { PersonAddressService } from '../../../../services/person-address.service';
import { FolderService } from '../../../../services/folder.service';
import { ExpertiseGqlService } from '../../../../services/expertise.gql-service';

type Selects<T> = {
    [Key in keyof T as `${Key}$`]: Observable<T[Key]>;
};

export type HasNextType<T> = {
    [Key in keyof T as `${Key}$`]: Observable<boolean>;
};

type LastValueSelects<T> = {
    [Key in keyof T]: () => Promise<T[Key]>;
};

export type AllSelects<T> = Selects<T> & LastValueSelects<T>;

export type Clears<T> = LastValueSelects<T>;

export type AllowedSelects<A = keyof { [Key in ActionEnum]: boolean }> = {
    [R in ResourceEnum as `${R}.${A}`]: Observable<boolean>;
};

export type Effects<T> = {
    [Key in keyof T]: T[Key];
};

export type OnSuccesses<T> = {
    [Key in keyof T]: EventEmitter<T[Key]>;
};

export type RefreshOn<T> = keyof T;
export type RefreshOnAny =
    | RefreshOn<ServiceService>
    | RefreshOn<PersonService>
    | RefreshOn<OrganizationService>
    | RefreshOn<UserService>
    | RefreshOn<FileService>
    | RefreshOn<DonorService>
    | RefreshOn<WarningService>
    | RefreshOn<DonationService>
    | RefreshOn<CollectionService>
    | RefreshOn<ExpectedDonationService>
    | RefreshOn<WorkplaceService>
    | RefreshOn<PersonAddressService>
    | RefreshOn<FolderService>
    | RefreshOn<ExpertiseGqlService>
    | string;

export const outsideParametersKnowOptionsKeys = [
    'asFilter',
    'asSinglePageFilter',
] as const;

/** !! MARK FIELD ON ARRAY ABOVE AS WELL !! **/
interface outsideParametersKnowOptions {
    asFilter?: boolean;
    asSinglePageFilter?: boolean;
}

export type outsideParametersOptions = Record<
    string,
    Observable<unknown> | unknown
> &
    outsideParametersKnowOptions;
