<div class="layout-topbar">
    <div class="topbar-left">
        <a
            #menubutton
            tabindex="0"
            class="menu-button p-trigger"
            (click)="onMenuButtonClick()"
        >
            <i class="pi pi-chevron-left"></i>
        </a>
        <img
                *ngIf="(darkLogo$ | async) === false"
                class="horizontal-logo"
                [routerLink]="['/']"
                src="../../../../../assets/images/XC_logo-horizont-inv.svg"
                alt="diamond-layout"
        />
        <img
                *ngIf="darkLogo$ | async"
                class="horizontal-logo"
                [routerLink]="['/']"
                src="../../../../../assets/images/XC_logo-horizont.svg"
                alt="diamond-layout"
        />
        <span class="topbar-separator"></span>
        <img
                *ngIf="(darkLogo$ | async) === false"
                src="../../../../../assets/images/XC_icon-inv.svg"
                class="h-full mobile-logo"
                alt="diamond-layout"
        />
        <img
                *ngIf="darkLogo$ | async"
                src="../../../../../assets/images/XC_icon.svg"
                class="h-full mobile-logo"
                alt="diamond-layout"
        />

        <app-organization-select></app-organization-select>
    </div>

    <app-sidebar></app-sidebar>
    <div class="layout-mask modal-in"></div>

    <div class="topbar-right">
        <ul *ngIf="pageStore.systemSettings$ | async as settings" class="topbar-menu">
            <li *ngIf="settings?.topbarVideocall" class="microphone">
                <a tabindex="0" (click)="onMicrophoneClick()" class="p-trigger">
                    <i class="pi pi-video"></i> <!--TODO: microphone icon-->
                </a>
            </li>
            <li *ngIf="settings?.topbarFullscreen" class="fullscreen">
                <a tabindex="0" (click)="onFullscreenClick()" class="p-trigger">
                    <i class="pi pi-window-maximize"></i> <!--TODO: fullscreen icon-->
                </a>
            </li>
            <li *ngIf="settings?.topbarSearch" class="search-item">
                <a tabindex="0" (click)="onSearchClick()" class="p-trigger">
                    <i class="pi pi-search"></i>
                </a>
            </li>
            <li *ngIf="settings?.topbarHelp" class="guide">
                <a tabindex="0" (click)="onGuideClick()" class="p-trigger">
                    <i class="pi pi-question"></i> <!--TODO: guide icon-->
                </a>
            </li>
            <li class="profile-item static sm:relative">
                <a
                    tabindex="0"
                    pStyleClass="@next"
                    enterFromClass="hidden"
                    enterActiveClass="scalein"
                    leaveToClass="hidden"
                    leaveActiveClass="fadeout"
                    [hideOnOutsideClick]="true"
                >
                    <img
                        src="../../../../../assets/images/userImage.png"
                        alt="diamond-layout"
                        class="profile-image"
                    />
                    <span class="profile-name font-bold">{{ name | async }}</span>
                </a>
                <ul
                    class="list-none px-3 py-2 m-0 border-round shadow-2 absolute hidden origin-top w-full sm:w-14rem mt-2 left-0 top-auto"
                >
                    <li>
                        <a
                            pRipple
                            class="flex p-2 border-round align-items-center transition-colors transition-duration-150 cursor-pointer"
                            (click)="onProfileClick()"
                        >
                            <i class="pi pi-user mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">{{ 'header.profile' | translate }}</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a
                            pRipple
                            class="flex p-2 border-round align-items-center transition-colors transition-duration-150 cursor-pointer"
                            (click)="onSettingsClick()"
                        >
                            <i class="pi pi-cog mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">{{ 'header.settings' | translate }}</span>
                            </span>
                        </a>
                    </li>
                    <li *ngIf="settings?.topbarUserEmail">
                        <a
                            pRipple
                            class="flex p-2 border-round align-items-center transition-colors transition-duration-150 cursor-pointer"
                            (click)="onEmailClick()"
                        >
                            <i class="pi pi-envelope mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">{{ 'header.email' | translate }}</span>
                            </span>
                        </a>
                    </li>
                    <li *ngIf="settings?.topbarUserOffice">
                        <a
                            pRipple
                            class="flex p-2 border-round align-items-center transition-colors transition-duration-150 cursor-pointer"
                            (click)="onMyOfficeClick()"
                        >
                            <i class="pi pi-calendar mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">{{ 'header.myOffice' | translate }}</span>
                            </span>
                        </a>
                    </li>
                    <li *ngIf="settings?.topbarUserReports">
                        <a
                            pRipple
                            class="flex p-2 border-round align-items-center transition-colors transition-duration-150 cursor-pointer"
                            (click)="onRequestsReportsClick()"
                        >
                            <i class="pi pi-volume-up mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">{{ 'header.requestsAndReports' | translate }}</span>
                            </span>
                        </a>
                    </li>
                    <li *ngIf="settings?.topbarUserReadMessages">
                        <a
                            pRipple
                            class="flex p-2 border-round align-items-center transition-colors transition-duration-150 cursor-pointer"
                            (click)="onReadMessagesClick()"
                        >
                            <i class="pi pi-comment mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">{{ 'header.readMessages' | translate }}</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <hr class="-mx-3 my-2" />
                    </li>
                    <li>
                        <a
                            pRipple
                            class="flex p-2 border-round align-items-center transition-colors transition-duration-150 cursor-pointer"
                            (click)="logout()"
                        >
                            <i class="pi pi-power-off mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">{{ 'header.logout' | translate }}</span>
                            </span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="static sm:relative">
                <a
                    tabindex="0"
                    enterFromClass="hidden"
                    enterActiveClass="scalein"
                    leaveToClass="hidden"
                    leaveActiveClass="fadeout"
                    (click)="onNotificationClick()"
                >
                    <i class="pi pi-bell"></i>
                    <span class="topbar-badge" *ngIf="((notificationsCount$ | async) || 0) > 0">{{ notificationsCount$ | async }}</span>
                </a>
            </li>
        </ul>
    </div>
</div>
