import { createAction, props } from '@ngrx/store';
import { LanguageEnum } from '../../shared/models/language.enum';
import { UserDto } from '../../shared/models/user.dto';

export const loginUser = createAction(
  '[Auth] Login',
  props<{ username: string; password: string; language?: LanguageEnum }>(),
);

export const loginUserSuccess = createAction(
  '[Auth] Login Success',
  props<{ data: { accessToken: string }; language?: LanguageEnum }>(),
);

export const loginUserFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: Error }>(),
);

export const loadUserData = createAction('[Auth] Load User Data');

export const loadUserDataSuccess = createAction(
  '[Auth] Load User Data Success',
  props<{ user: UserDto }>(),
);

export const loadUserDataFailure = createAction(
  '[Auth] Load User Data Failure',
  props<{ error: Error }>(),
);

export const logoutUser = createAction('[Auth] Logout');
