import { addressFrag } from './common.types';
import { medicalProcedureFrag } from './medical-procedure.types';
import { personFrag } from './person.types';
import { serviceUserFrag } from './user.types';

export const performedProcedureFrag = `
    applications
    billingStatus
    billingReasonForCancellation
    medicalProcedure {
        ${medicalProcedureFrag}
    }
`;

export const performedProcedureGridItemFrag = `
    workVisitId
    code
    name
    date
    applications
    price
    workId
    insuranceAbbr
    insuranceType
    serviceExpertise
    billingDocumentId
    billingStatus
    billingReasonForCancellation
    documentNumber
    folderId
    personId
    person {
        ${personFrag}
    }
    userId
    user {
        ${serviceUserFrag}
    }
`;

export const workDataFrag = `
    workData {
        ... on WorkDataVisitObject {
            assistantId
            distanceFrom
            distanceTo
            id
            medication
            note
            state
            visitFrom
            visitTo
            addressFrom {
                ${addressFrag}
            }
            addressTo{
                ${addressFrag}
            }
            voucherNumber
            insuranceAbbr
            contactType
            signed
            locked
            billingStatus
            billingReasonForCancellation
            billingDocumentNumber
            performedProcedures {
                ${performedProcedureFrag}
            }
            systolicPressure
            diastolicPressure
            pulse
            breath
            glycemia
            spo2
            pain
            weight
            pulseByBeats
            pulseByTouch
        }
    }
`;

export const workFrag = `
    id
    date
    folderId
    workType
    ${workDataFrag}
`;

export const workGridItemFrag = `
    date
    id
    folderId
    serviceId
    person {
        ${personFrag}
    }
    userId
    user {
        ${serviceUserFrag}
    }
    workType
    ${workDataFrag}
`;

