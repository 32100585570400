<nav class="layout-breadcrumb last-strong">
  <xng-breadcrumb [separator]="itemSeparator">
    <ng-container *xngBreadcrumbItem="let breadcrumb">
      <ng-template [ngIf]="breadcrumb !== BreadcrumbsEnum.Content" [ngIfElse]="home">
        <ng-container>
          <span class="basic-text-breadcrumb">
            {{ 'breadcrumbs.' + (breadcrumb | breadcrumbFirstPart) | translate }}{{ (breadcrumb | breadcrumbDoesContainSecondPart)? ':' : ''}}
            {{ breadcrumb | breadcrumbSecondPart }}
          </span>
        </ng-container>
      </ng-template>
      <ng-template #home>
        <ng-container>
          <span class="home-breadcrumb">
            <i class="pi pi-home"></i>
          </span>
        </ng-container>
      </ng-template>
    </ng-container>
  </xng-breadcrumb>
  <ng-template #itemSeparator>
    <span class="separator-breadcrumb">
      <i class="pi pi-chevron-right"></i>
    </span>
  </ng-template>
</nav>
