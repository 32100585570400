import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { OkObject, WorkplaceGridObject, WorkplaceObject } from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { WorkplaceCreateInput } from '@libs/types/src/services/input-type/services/workplace-create.input';
import { folderWorkplaceFrag, workplaceFrag } from './types/service.types';
import { WorkplaceUpdateInput } from '@libs/types/src/services/input-type/services/workplace-update.input';
import { WorkplaceDeleteInput } from '@libs/types/src/services/input-type/services/workplace-delete.input';
import { filterFrag } from './types/filter.types';
import {
    WorkplaceUnassignToFolderInput,
} from '@libs/types/src/services/input-type/services/workplace-unassign-to-folder.input';
import {
    WorkplaceAssignToFolderInput,
} from '@libs/types/src/services/input-type/services/workplace-assign-to-folder.input';
import { FolderWorkplaceUpdateInput } from '@libs/types/src/services/input-type/services/folder-workplace-update.input';
import { FolderWorkplaceGridObject } from '@libs/types/src/services/object-type/folder-workplace.object';
import { FolderWorkplaceCreateInput } from '@libs/types/src/services/input-type/services/folder-workplace.create-input';

@Injectable()
export class WorkplaceService extends GqlService {

    public createWorkplace(input: WorkplaceCreateInput) {
        return this.mutate<WorkplaceObject>(
            `
                mutation($input: WorkplaceCreateInput!) {
                    workplace {
                        create(input: $input) {
                            ${workplaceFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getWorkplaces(input: GridInput | null = null) {
        return this.query<WorkplaceGridObject>(
            `
                query($input: GridInput) {
                    workplace {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${workplaceFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderWorkplaces(input: GridInput | null = null) {
        return this.query<FolderWorkplaceGridObject>(
            `
                query($input: GridInput) {
                    workplace {
                        filterFolderWorkplace(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderWorkplaceFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateWorkplace(input: WorkplaceUpdateInput) {
        return this.mutate<WorkplaceObject>(
            `
                mutation($input: WorkplaceUpdateInput!) {
                    workplace {
                        update(input: $input) {
                            ${workplaceFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteWorkplace(input: WorkplaceDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceDeleteInput!) {
                    workplace {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public assignWorkplaceToFolder(input: WorkplaceAssignToFolderInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceAssignToFolderInput!) {
                    workplace {
                        assignToFolder(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public unassignWorkplaceFromFolder(input: WorkplaceUnassignToFolderInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WorkplaceUnassignToFolderInput!) {
                    workplace {
                        unassignFromFolder(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderWorkplace(input: FolderWorkplaceCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderWorkplaceCreateInput!) {
                    workplace {
                        createFolderWorkplace(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderWorkplace(input: FolderWorkplaceUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderWorkplaceUpdateInput!) {
                    workplace {
                        updateFolderWorkplace(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
