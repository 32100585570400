import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { DonationGridObject } from '../../../../types/src/chcrm/object-type/donation/donation.object';
import { donationGridItemDetailFrag, donationGridItemFrag } from './types/donation.types';
import {
    EnableSynchronizationInput,
} from '../../../../types/src/chcrm/input-type/donation/enable-synchronization.input';
import { DonationAssignCollectionInput, OkObject } from '@it2go/types';
import { SystemSettingsObject } from '../../../../types/src/chcrm/object-type/system/system-settings.object';
import { systemSettingsFrag } from './types/system-settings.types';
import { ResendDonationEmailInput } from '../../../../types/src/chcrm/input-type/donation/resend-donation-email.input';
import { DonationDeleteInput } from '../../../../types/src/chcrm/input-type/donation/donation-delete.input';
import { Observable } from 'rxjs';
import { filterFrag } from './types/filter.types';
import { GqlService } from '../modules/content/service/gql.service';
import { DonationUpdateInput } from '@libs/types/src/chcrm/input-type/donation/donation-update.input';

@Injectable()
export class DonationService extends GqlService {
    getDonations(input: GridInput | null) {
        return this.query<DonationGridObject>(
            `
                query($input: GridInput) {
                    donation {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${donationGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getDetailDonations(input: GridInput | null) {
        return this.query<DonationGridObject>(
            `
                query($input: GridInput) {
                    donation {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${donationGridItemDetailFrag}
                            }
                        }
                    }
                }
            `,
            { input: { ...input, tableKey: 'detailDonations' } },
        );
    }

    enableSynchronization(input: EnableSynchronizationInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: EnableSynchronizationInput!) {
                    systemSettings {
                        enableSynchronization(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getSystemSettings() {
        return this.query<SystemSettingsObject>(
            `
                query {
                    systemSettings {
                        get {
                            ${systemSettingsFrag}
                        }
                    }
                }
            `,
        );
    }

    resendEmail(input: ResendDonationEmailInput) {
        return this.mutate(
            `
                mutation ($input: ResendDonationEmailInput!) {
                    donation {
                        resendEmail(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteDonation(input: DonationDeleteInput): Observable<OkObject> {
        return this.mutate(
            `
                mutation($input: DonationDeleteInput!) {
                    donation {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateDonation(input: DonationUpdateInput): Observable<OkObject> {
        return this.mutate(
            `
                mutation($input: DonationUpdateInput!) {
                    donation {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public assignCollection(input: DonationAssignCollectionInput): Observable<OkObject> {
        return this.mutate(
            `
                mutation($input: DonationAssignCollectionInput!) {
                    donation {
                        assignCollection(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
