<div class="layout-sidebar">
    <a [routerLink]="['/']" class="logo">
        <div class="logo-image pre-app-name">
            <img *ngIf="(darkLogo$ | async) === false" src="../../../../../assets/images/XC_icon-inv.svg" class="w-full"/>
            <img *ngIf="darkLogo$ | async" src="../../../../../assets/images/XC_icon.svg" class="w-full"/>
        </div>
        <!--    Ani se neptejte, prostě nějaký ojeb kolem té šablony pro přepnutí loga    -->
        <div class="horizontal-logo app-name" style="width:9rem;">
            <img *ngIf="(darkLogo$ | async) === false" src="../../../../../assets/images/XC_logo-horizont-inv.svg" class="w-full"/>
            <img *ngIf="darkLogo$ | async" src="../../../../../assets/images/XC_logo-horizont.svg" class="w-full"/>
        </div>
    </a>
    <div class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>
