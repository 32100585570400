import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breadcrumbDoesContainSecondPart',
  standalone: true,
})
export class BreadcrumbDoesContainSecondPartPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return false;
    }
    return value.split('|').length > 1;
  }
}
