import { Injectable } from '@angular/core';
import { GetFolderInput, OkObject, PersonGridObject } from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { FolderTerminateInput } from '@libs/types/src/services/input-type/folder/folder.terminate-input';
import { folderStatusHistoryFrag } from './types/folder.types';
import { filterFrag } from './types/filter.types';
import {
    FolderStatusHistoryGridObject,
} from '@libs/types/src/services/object-type/folder/folder-status-history.object';
import { GridInput } from '@it2go/data-grid';
import {
    FolderTerminationCheckObject,
} from '@libs/types/src/services/object-type/folder/folder.termination-check-object';
import { FolderCloseVouchersInput } from '@libs/types/src/services/input-type/folder/folder.close-vouchers-input';

@Injectable()
export class FolderService extends GqlService {

    public terminateFolder(input: FolderTerminateInput) {
        return this.mutate<PersonGridObject>(
            `
                mutation($input: FolderTerminateInput!) {
                    folder {
                        terminate(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderStatuses(input: GridInput | null = null) {
        return this.query<FolderStatusHistoryGridObject>(
            `
                query($input: GridInput) {
                    folder {
                        filterStatuses(input: $input) {
                            ${filterFrag} 
                            items {
                                ${folderStatusHistoryFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getCheckTermination(input: GetFolderInput) {
        return this.query<FolderTerminationCheckObject>(
            `
                query($input: GetFolderInput!) {
                    folder {
                        checkTermination(input: $input) {
                            voucherIds
                        }
                    }
                }
            `,
            { input },
        );
    }

    public closeVouchers(input: FolderCloseVouchersInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderCloseVouchersInput!) {
                    folder {
                        closeVouchers(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
