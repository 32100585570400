import { Injectable } from '@angular/core';
import { AuthenticatedUserObject, OkObject, RuleObject } from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { MsLogonInput } from '@libs/types/src/authorization/input-type/ms-logon.input';
import { ForgottenPasswordInput } from '@libs/types/src/authorization/input-type/forgotten-password.input';
import { ResetPasswordInput } from '@libs/types/src/authorization/input-type/reset-password.input';
import { LoginInput } from '@libs/types/src/authorization/input-type/login.input';
import { profileFrag } from './types/profile.types';
import { StringObject } from '@libs/types/src/common/object-type/string.object';
import { LoginCodeInput } from '@libs/types/src/authorization/input-type/login-code.input';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService extends GqlService {

    getRules() {
        return this.query<RuleObject[]>(
            `
                query {
                    rule {
                        userRules {
                            action
                            resource
                        }
                    }
                }
            `,
        );
    }

    public whoami() {
        return this.query<AuthenticatedUserObject>(
            `
                query {
                    authentication {
                        whoami {
                            ${profileFrag}
                        }
                    }
                }
            `,
        );
    }

    public msLogon(input: MsLogonInput) {
        return this.mutate<AuthenticatedUserObject>(
            `
                mutation($input: MsLogonInput!) {
                    authorization {
                        msLogon(input: $input) {
                            ${profileFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public forgottenPassword(input: ForgottenPasswordInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ForgottenPasswordInput!) {
                    authorization {
                        forgottenPassword(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public resetPassword(input: ResetPasswordInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ResetPasswordInput!) {
                    authorization {
                        resetPassword(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public login(input: LoginInput) {
        return this.mutate<AuthenticatedUserObject>(
            `
                mutation($input: LoginInput!) {
                    authorization {
                        login(input: $input) {
                            ${profileFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public loginCode(input: LoginCodeInput) {
        return this.mutate<AuthenticatedUserObject>(
            `
                mutation($input: LoginCodeInput!) {
                    authorization {
                        loginCode(input: $input) {
                            ${profileFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public logout() {
        return this.mutate<OkObject>(
            `
                mutation {
                    authorization {
                        logout {
                            ok
                        }
                    }
                }
            `,
        );
    }

    public generateQrCode() {
        return this.query<StringObject>(
            `
                query {
                    authorization {
                        generateQrCode {
                            value
                        }
                    }
                }
            `,
        );
    }

    public disable2fa() {
        return this.mutate<OkObject>(
            `
                mutation {
                    authorization {
                        disable2fa {
                            ok
                        }
                    }
                }
            `,
        );
    }

}
