import { addressFrag } from './common.types';

export const workplaceFrag = `
    id
    name
    dateFrom
    dateTo
    address {
        ${addressFrag}
    }
`;

export const folderWorkplaceFrag = `
    id
    workplaceId
    folderId
    name
    dateFrom
    dateTo
`;

export const serviceFrag = `
    id
    expertise {
        code
        name
    }
    color
    name
    organizationId
    serviceTypeId
    useFormsCard
    formsCardName
    address {
        ${addressFrag}
    }
    workplaces {
        ${workplaceFrag}
    }
`;

export const serviceGridItemFrag = `
    id
    name
    color
    expertise {
        code
        name
    }
    workplaces {
        id
        name
        dateFrom
        dateTo
    }
`;

export const serviceTypeGridItemFrag = `
    id
    name
    organizationId
    expertise {
        code
        name
    }
    useFormsCard
    formsCardName
`;

export const medicalProcedureFrag = `
    code
    description
    duration
`;
