import { Component } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent {
    time$: Observable<Date> = timer(0, 1000).pipe(
        map(() => new Date()),
    );
}
