export const donationGridItemFrag = `
    id
    receivedAt
    donorId
    donorName
    isDonation
    donationForm {
        id
        name
    }
    collectionId
    collectionName
    amount
    source
`;

export const donationGridItemDetailFrag = `
    id
    createdAt
    createdBy
    receivedAt
    originalReceivedAt
    donorId
    donorName
    isDonation
    donationForm {
        id
        name
    }
    collectionId
    collectionName
    amount
    originalAmount
    source
    transactionId
    variableSymbol
    constantSymbol
    specificSymbol
    debtorIban
    debtorName
    creditorIban
    projectId
    webGatewayId
    newsletter
    webpageSource
    organizationName
    sentEmails {
        date
        kind
        path
    }
    recurringPayment
    comment
    reports {
        donorId
        date
        comment
        isDone
    }
    amountWithoutFee
    accountingDate
`
