<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="w-full sm:w-18rem"
>
    <div class="right-side">
        <p-accordion
            *ngIf="{types: notificationTypes$ | async, byTypes: notificationsByTypes$ | async} as notification">
            <p-accordionTab *ngFor="let notifType of notification.types">
                <ng-template pTemplate="header">
                    <div class="flex justify-content-between">
                        <span>{{ notifType }} ({{ getUnreadCount(notification.byTypes?.[notifType] || []) }})</span>
                    </div>
                </ng-template>
                <div
                    class="mb-2 pb-1 relative"
                    *ngFor="let notif of notification.byTypes?.[notifType]"
                >
                    <div class="flex justify-content-end gap-2 mb-1">
                        <a (click)="handleReadStatus(notif, $event)" class="pi pi-envelope cursor-pointer"></a>
                        <a (click)="handleRemove(notif, $event)" class="pi pi-trash cursor-pointer"></a>
                    </div>
                    <a
                        [routerLink]="notif.link"
                        class="flex flex-row align-content-center align-items-center hover:surface-hover on-hover-display"
                        [class.is-unread]="!notif.isRead"
                    >
                        <p-avatar
                            *ngIf="notif.pictureUrl"
                            shape="circle"
                            size="large"
                            [label]="
                                notif.pictureUrl
                                  ? undefined
                                  : getInitials(notif.authorName)
                            "
                            [image]="notif.pictureUrl"
                            class="mr-2"
                        />
                        <div class="w-full ml-auto">
                            <div class="flex flex-row justify-content-between">
                                <div
                                    class="text-color"
                                    [class]="notif.isRead ? 'font-semibold' : 'font-bold'"
                                >
                                    {{ notif.authorName }}
                                </div>
                                <div class="text-primary text-xs">
                                    {{ notif.createdAt | date: 'short' }}
                                </div>
                            </div>
                            <div
                                class="text-primary"
                                [class]="notif.isRead ? 'font-semibold' : 'font-bold'"
                            >
                                {{ notif.title }}
                            </div>
                            <div
                                class="text-color-secondary overflow-hidden text-overflow-ellipsis text-sm font-light"
                            >
                                {{ notif.subtitle }}
                            </div>
                        </div>
                    </a>
                </div>
            </p-accordionTab>
        </p-accordion>

        <p-divider>
            <span class="pi pi-clock"></span>
            <span class="ml-2">{{ 'notifications.recentlyVisited' | translate }}</span>
        </p-divider>
        <div class="flex flex-column gap-2 mt-3">
            <div *ngFor="let group of (store.history$ | async)" class="w-full">
                <ng-container *ngIf="group['items'].length <= 1">
                    <ng-container *ngIf="translateLink(0, group['items'][0]) as names">
                        <p-button styleClass="p-button-secondary w-full panelButton"
                                  (onClick)="navigate(group['items'][0])">
                            <div class="text-left">
                                <ng-container *ngFor="let name of names; index as i">
                                    <i *ngIf="i > 0" class="pi pi-angle-right"></i>
                                    <span>{{ name }}</span>
                                </ng-container>
                            </div>
                        </p-button>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="group['items'].length > 1">
                    <p-accordion [activeIndex]="-1">
                        <p-accordionTab [header]="translateHeader(group)">
                            <ng-container *ngFor="let item of group['items']">
                                <ng-container *ngIf="translateLink(group.match, item) as names">
                                    <p-button
                                        styleClass="p-button-secondary p-button-link w-full"
                                        (onClick)="navigate(item)">
                                        <div class="text-left">
                                            <ng-container *ngFor="let name of names; index as i">
                                                <i *ngIf="i > 0" class="pi pi-angle-right"></i>
                                                <span>{{ name }}</span>
                                            </ng-container>
                                        </div>
                                    </p-button>
                                </ng-container>
                            </ng-container>
                        </p-accordionTab>
                    </p-accordion>
                </ng-container>
            </div>
        </div>
    </div>
</p-sidebar>
