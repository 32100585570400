import { Injectable } from '@angular/core';
import { VisitHistoryGridItemObject, VisitHistoryObject } from '@it2go/types';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { NavigatorService } from '../../../../services/navigator.service';
import { getVisitHistory, watchEvents, watchVisitHistory } from '../../../../store/global/global.actions';
import { selectCurrentUserId, selectVisitHistory } from '../../../../store/global/global.selectors';
import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';

interface State {
    visitHistory: VisitHistoryGridItemObject[],
}

const defaultState: State = {
    visitHistory: [],
};

export interface HistoryGroup {
    match: number;
    items: VisitHistoryObject[];
}

@Injectable()
export class NotificationsStore extends ComponentStoreFactory<State, NavigatorService> {

    history$ = this.globalStore!.select(selectVisitHistory).pipe(map((history) => {
        const groups: HistoryGroup[] = [];
        let currentGroup: VisitHistoryObject[] = [];
        let lastIndex = -1;

        (history || []).forEach((it) => {
            const data = JSON.parse(it.data || '{}');
            const matchIndex = Object.values(data).findIndex((d: any) => !d.toString().startsWith('__'));

            if (matchIndex === -1 || (matchIndex > 0 && matchIndex !== lastIndex)) {
                if (currentGroup.length) {
                    groups.push({
                        match: lastIndex,
                        items: currentGroup,
                    });
                    currentGroup = [];
                }
            }

            if (matchIndex === -1) {
                groups.push({
                    match: -1,
                    items: [it],
                })
            } else {
                currentGroup.push(it);
            }
            lastIndex = matchIndex;
        });

        if (currentGroup.length) {
            groups.push({
                match: lastIndex,
                items: currentGroup,
            });
        }

        return groups.slice(0, 10);
    }));

    constructor(
        svc: NavigatorService,
        store: Store,
    ) {
        super(defaultState, store, svc);
        store.dispatch(getVisitHistory());
        store.dispatch(watchEvents());
        this.subs.push(
            this.globalStore!.select(selectCurrentUserId).subscribe((userId) => {
                if (userId) this.globalStore?.dispatch(watchVisitHistory());
            }),
        )
    }

}
