export const collectionGridItemFrag = `
    id
    active
    longTerm
    name
    comment
    category {
        id
        category
    }
    variableSymbols
    createdAt
`;

export const collectionLinkFrag = `
    id
    name
`;

export const collectionFrag = `
    id
    name
    category {
        id
        category
    }
    active
    longTerm
    darujmePledgeIds
    variableSymbols
    comment
    donationCount
    createdAt
    createdBy
    parentCollection {
        ${collectionLinkFrag}
    }
    childrenCollections {
        ${collectionLinkFrag}
    }
`;

export const collectionStatistic = `
    total
    count
    totalWithChildren
    countWithChildren
`;

export const collectionStatistics = `
    id
    name
    donationFormName
    donationFormId
    average
    total
    count
    from
    period
    maxCount
`;
