import { Component, ElementRef, OnDestroy } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { Store } from '@ngrx/store';
import { selectUserSettings } from '../../store/layout.selectors';
import { map } from 'rxjs/operators';
import { defaultMenuThemes } from '../../../content/service/types/user.types';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnDestroy {
    darkLogo$ = this.store.select(selectUserSettings).pipe(
        map((settings) =>
            defaultMenuThemes.find((it) => it.name == settings.menuTheme)?.logoColor == 'dark',
        ),
    );

    constructor(
        public layoutService: LayoutService,
        public el: ElementRef,
        private readonly store: Store,
    ) {
    }

    resetOverlay(): void {
        if (this.layoutService.state.overlayMenuActive) {
            this.layoutService.state.overlayMenuActive = false;
        }
    }

    ngOnDestroy(): void {
        this.resetOverlay();
    }

}
