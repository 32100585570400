import { createAction, props } from '@ngrx/store';
import {
    AuthenticatedUserObject,
    DeleteNotificationInput,
    NotificationGridObject,
    NotificationObject,
    OkObject,
    ReadNotificationInput,
} from '@it2go/types';
import { GridInput } from '@it2go/data-grid';

export const
    readNotification = createAction('[Notifications] Read Notification', props<{
        input: ReadNotificationInput;
    }>()),
    readNotificationSuccess = createAction('[Notifications] Read Notification Success', props<{
        read: OkObject;
    }>()),
    readNotificationFailure = createAction('[Notifications] Read Notification Failure', props<{
        read: null;
    }>());

export const
    deleteNotification = createAction('[Notifications] Delete Notification', props<{
        input: DeleteNotificationInput;
    }>()),
    deleteNotificationSuccess = createAction('[Notifications] Delete Notification Success', props<{
        notification: OkObject;
    }>()),
    deleteNotificationFailure = createAction('[Notifications] Delete Notification Failure', props<{
        notification: null;
    }>());

export const
    getNotifications = createAction('[Notifications] Get Notifications', props<{ input: GridInput | null }>()),
    getNotificationsSuccess = createAction('[Notifications] Get Notifications Success', props<{
        notification: NotificationGridObject;
    }>()),
    getNotificationsFailure = createAction('[Notifications] Get Notifications Failure', props<{
        error: any;
    }>());

export const
    receiveNotification = createAction('[Notifications] Receive Notification', props<{ user: AuthenticatedUserObject }>()),
    receiveNotificationSuccess = createAction('[Notifications] Receive Notification Success', props<{
        notification: NotificationObject
    }>());

export const getNotificationTypes = createAction('[Notifications] Get Notification Types'),
    getNotificationTypesSuccess = createAction('[Notifications] Get Notification Types Success', props<{
        notificationType: string[];
    }>()),
    getNotificationTypesFailure = createAction('[Notifications] Get Notification Types Failure', props<{
        error: any;
    }>());
