export const medicationGridItemFrag = `
    code
    registeredName
    name
    nameAddition
    strength
    form
    packaging
    administration
    packageLanguage
    packageType
    regime
    substances
    atc
    atcName
    spcPdf
    pilPdf
    packageTextPdf
`;

export const compensationGridItemFrag = `
    code
    description
    priority
`;

export const diagnosisGridItemFrag = `
    ageFrom
    ageTo
    bonified902
    bonified913
    bonified925
    code
    name
    parentName
    selectable
    sex
    sign
`;

export const folderMedicationGridItemFrag = `
    id
    medicationCode
    customMedication
    customMedicationName
    from
    to
    packageTo
    active
    internalNote
    dosingNote
    prescribedBy {
        id
        name
        surname
        titleAfterName
        titleBeforeName
    }
    dosingPeriod {
        days
        hours
        custom
    }
    dosing {
        morning
        forenoon
        noon
        afternoon
        evening
        night
        hours
    }
`;
